import React from 'react'

export const DownArrow: React.FC = () => {
  return <svg className="icon arrow-down" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <path d="M14.644,18.53L7.48,11.592m14.916,0-7.164,6.936"/>
  </svg>
}

export const UpArrow: React.FC = () => {
  return <svg className="icon arrow-up"xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <path d="M14.768,11.47L7.6,18.408m14.916,0-7.164-6.936"/>
  </svg>
}

export const LeftArrow: React.FC = () => {
  return <svg className="icon arrow-left" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <path d="M11.5,14.7l7-7.2m0,15-7-7.2"/>
  </svg>
}

export const RightArrow: React.FC = () => {
  return <svg className="icon arrow-right" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <path d="M18.624,14.829l-7-7.2m0,15,7-7.2"/>
  </svg>
}

export const SpeechIcon: React.FC = () => {
  return <svg className="icon icon-speech" data-name="Speech Icon" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
    <path d="M46.815,37.849l1.7,10.414-9.979-7.57A40.268,40.268,0,0,1,30.5,41.5c-13.808,0-25-6.716-25-15s11.192-15,25-15,25,6.716,25,15C55.5,31.039,52.124,35.1,46.815,37.849Z"/>
    <path d="M20.5,26A3.5,3.5,0,1,1,17,29.5,3.5,3.5,0,0,1,20.5,26Zm10,0A3.5,3.5,0,1,1,27,29.5,3.5,3.5,0,0,1,30.5,26Zm10,0A3.5,3.5,0,1,1,37,29.5,3.5,3.5,0,0,1,40.5,26Z"/>
  </svg>
}

export const CorrectIcon: React.FC = () => {
  return <div className="icon icon-correct" />;
}

export const IncorrectIcon: React.FC = () => {
  return <div className="icon icon-incorrect" />;
}

export const AlarmIcon: React.FC = () => {
  return <div className="icon icon-alarm" />;
}

export const CalendarIcon: React.FC = () => {
  return <div className="icon icon-calendar" />;
}

export const CloseIcon: React.FC = () => {
  return <div className="icon icon-close" />;
}

export const QuoteOpenIcon = () => {
  return(
    <svg className="open-quote" id="Quotes_-_Open" data-name="Quotes - Open" xmlns="http://www.w3.org/2000/svg" width="90" height="70" viewBox="0 0 90 70">
      <path id="Quote_Single" data-name="Quote Single" className="icon quote-open" d="M49.666,31.666H76a7,7,0,0,1,7,7V58a7,7,0,0,1-7,7H56.664a7,7,0,0,1-7-7V31.666Zm10,0h-10A26.667,26.667,0,0,1,76.334,5V15A16.667,16.667,0,0,0,59.666,31.666Z"/>
      <path id="Quote_Single-2" data-name="Quote Single" className="icon quote-open" d="M7,31.666H33.331a7,7,0,0,1,7,7V58a7,7,0,0,1-7,7H14a7,7,0,0,1-7-7V31.666Zm10,0H7A26.668,26.668,0,0,1,33.665,5V15A16.668,16.668,0,0,0,17,31.666Z"/>
    </svg>
  )
};

export const QuoteCloseIcon = () => {
  return(
    <svg className="close-quote"  id="Quotes_-_Close" data-name="Quotes - Close" xmlns="http://www.w3.org/2000/svg" width="90" height="70" viewBox="0 0 90 70">
      <path id="Quote_Single" data-name="Quote Single" className="icon quote-close" d="M14,5H33.342a7,7,0,0,1,7,7V38.344H14a7,7,0,0,1-7-7V12A7,7,0,0,1,14,5ZM30.33,38.333h10A26.668,26.668,0,0,1,13.663,65V55A16.667,16.667,0,0,0,30.33,38.333Z"/>
      <path id="Quote_Single-2" data-name="Quote Single" className="icon quote-close" d="M56.654,5H76a7,7,0,0,1,7,7V38.344H56.654a7,7,0,0,1-7-7V12A7,7,0,0,1,56.654,5ZM73,38.333H83A26.668,26.668,0,0,1,56.332,65V55A16.667,16.667,0,0,0,73,38.333Z"/>
    </svg>
  )
};

export const PointerTop = () => {
  return(
    <svg id="Quotes_-_Close" data-name="Quotes - Close" xmlns="http://www.w3.org/2000/svg" width="90" height="70" viewBox="0 0 90 70">
      <path id="Quote_Single" data-name="Quote Single" className="icon quote-close" d="M14,5H33.342a7,7,0,0,1,7,7V38.344H14a7,7,0,0,1-7-7V12A7,7,0,0,1,14,5ZM30.33,38.333h10A26.668,26.668,0,0,1,13.663,65V55A16.667,16.667,0,0,0,30.33,38.333Z"/>
      <path id="Quote_Single-2" data-name="Quote Single" className="icon quote-close" d="M56.654,5H76a7,7,0,0,1,7,7V38.344H56.654a7,7,0,0,1-7-7V12A7,7,0,0,1,56.654,5ZM73,38.333H83A26.668,26.668,0,0,1,56.332,65V55A16.667,16.667,0,0,0,73,38.333Z"/>
    </svg>
  )
};

export const PointerBot = () => {
  return(
    <svg id="Quotes_-_Close" data-name="Quotes - Close" xmlns="http://www.w3.org/2000/svg" width="90" height="70" viewBox="0 0 90 70">
      <path id="Quote_Single" data-name="Quote Single" className="icon quote-close" d="M14,5H33.342a7,7,0,0,1,7,7V38.344H14a7,7,0,0,1-7-7V12A7,7,0,0,1,14,5ZM30.33,38.333h10A26.668,26.668,0,0,1,13.663,65V55A16.667,16.667,0,0,0,30.33,38.333Z"/>
      <path id="Quote_Single-2" data-name="Quote Single" className="icon quote-close" d="M56.654,5H76a7,7,0,0,1,7,7V38.344H56.654a7,7,0,0,1-7-7V12A7,7,0,0,1,56.654,5ZM73,38.333H83A26.668,26.668,0,0,1,56.332,65V55A16.667,16.667,0,0,0,73,38.333Z"/>
    </svg>
  )
};