import React from 'react';
import { generateUUID } from '../../../utils/uuid';

interface Props {
  src: string;
};

export const Video = ({ src }: Props) => {
  return <div className="video-box">
    <div className="video-container">
      <div className="iframe-wrap">
        <iframe className="video" title={`${generateUUID()}-video`} src={src} frameBorder="0" allowFullScreen />
      </div>
    </div>
  </div>;
}