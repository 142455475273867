import React, { useContext, useState } from 'react';
import { TimeContext } from '../../../App';
import { scrollDown } from '../../../utils/scrollDown';

interface Props {
  trigger: string;
  text?: string;
};

export const Trigger = ({ trigger, text }: Props) => {
  const [clicked, setClicked] = useState(false);
  const { setGates, gates } = useContext(TimeContext);

  const handleTrigger = () => {
    if (!clicked) {
      setGates && gates && setGates([...gates, trigger]);
      setClicked(true);
    }

    scrollDown();
  }

  return <button onClick={handleTrigger} className="green trigger">{text ? text : 'Continue'}</button>;
}