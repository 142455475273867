import React, { useState, useEffect, useRef, useContext } from 'react';
import { Image } from '../../atoms';
import { SpeechBox, ContentBox } from '../../molecules';
import { TimeContext } from '../../../App';
import { gsap } from "gsap";

type ImageDisplay = 'fill' | 'contain';
type buttonDisplay = 'row' | 'column';

interface Option {
  option: React.ReactNode;
  correct?: boolean;
}
interface Feedback {
  incorrectSrc?: string;
  correctSrc?: string;
  src?: string;
  alt?: string;
  incorrect?: React.ReactNode;
  correct?: React.ReactNode;
  generic?: React.ReactNode;
}
interface Question {
  multi?: boolean;
  heading: string;
  scenario?: React.ReactNode;
  question: React.ReactNode;
  src: string;
  alt: string;
  options: Option[];
  feedback: Feedback;
}
interface Results {
  src?: string;
  alt?: string;
  content: React.ReactNode;
}
interface Props {
  imageDisplay?: ImageDisplay;
  buttonDisplay?: buttonDisplay;
  stackedButtons?: boolean;
  questions: Question[];
  results?: Results;
  multi?: boolean;
  trigger: string;
  nowTrigger?: string;
  hoursTrigger?: string;
};

interface Submission {
  submitted: boolean;
  completed: boolean;
  correct?: boolean;
}

export const Quiz = ({ imageDisplay = 'fill', buttonDisplay = 'column', questions, results, stackedButtons, multi, trigger, nowTrigger, hoursTrigger }: Props) => {
  const [unlocked, setUnlocked] = useState(false);
  const { setGates, gates, onlyNow, onlyHours } = useContext(TimeContext);
  const [qIndex, setQIndex] = useState(0);
  const [imgSrc, setImgSrc] = useState<string>('');
  const [imgAlt, setImgAlt] = useState<string>('');
  const quizRef = useRef<HTMLDivElement | null>(null);
  const fbRef = useRef<HTMLDivElement | null>(null);
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
  const [submission, setSubmission] = useState<Submission>({ submitted: false, completed: false });
  const [currQuestion, setCurrQuestion] = useState<Question | null>(null);

  useEffect(() => {
    setCurrQuestion(questions[qIndex]);
  }, [qIndex, currQuestion, questions]);

  useEffect(() => {
    if (!imgSrc) {
      const { src, alt } = questions[qIndex] || {};
      src && setImgSrc(src);
      alt && setImgAlt(alt);
    }
  }, [questions, qIndex, imgSrc])

  useEffect(() => {
    const { completed } = submission;
    const { src, alt } = results || {};
    if (completed) {
      src && setImgSrc(src);
      alt && setImgAlt(alt);
    }
  }, [submission, results]);

  const reset = () => {
    setSubmission({ submitted: false, completed: false });
    setSelectedIndexes([]);

    if (quizRef.current !== null) {
      setTimeout(() => {
        if (quizRef.current) {
          const yOffset = -80; 
          const y = quizRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 200);
    }
  }

  const handleResetQuiz = () => {
    setQIndex(0);
    const { src, alt } = questions[0] || {};
    src && setImgSrc(src);
    alt && setImgAlt(alt);
    reset();
  }

  const handleResetQuestion = () => {
    reset();
  }

  const handleNextQuestion = () => {
    if (qIndex === questions.length - 1) {
      setSubmission({ submitted: false, completed: true });
    } else {
      setQIndex(qIndex + 1);
      const { src, alt } = questions[qIndex + 1] || {};
      src && setImgSrc(src);
      alt && setImgAlt(alt);
      reset();
    }

    if (qIndex === questions.length - 1 && !unlocked) {
      setUnlocked(true);
      setGates && gates && setGates([...gates, trigger]);

      if (onlyNow && nowTrigger) {
        setGates && gates && setGates([...gates, nowTrigger]);
      }

      if (onlyHours && hoursTrigger) {
        setGates && gates && setGates([...gates, hoursTrigger]);
      }

      setTimeout(() => {
        if (quizRef.current) {
          const yOffset = -80; 
          const y = quizRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 200);
    }
  }

  const handleOptionClick = (i: number) => {
    const selected = selectedIndexes.indexOf(i);

    if (selected >= 0) {
      const filtered = selectedIndexes.filter(index => index !== i);
      setSelectedIndexes(filtered);
    } else {
      if (selectedIndexes.length > 0 && !multi) setSelectedIndexes([i]);
      else setSelectedIndexes([...selectedIndexes, i]);
    }
  }

  const handleSubmission = () => {
    const { options, feedback } = currQuestion || {};
    const { correctSrc, incorrectSrc, alt } = feedback || {};

    const correct = options && [...selectedIndexes].filter((selectedIndex: number) => {
      const { correct } = options[selectedIndex];
      if (correct) return false;
      return true;
    }).length === 0;

    setSubmission({ ...submission, submitted: true, correct });

    if (correct && correctSrc) {
      setImgSrc(correctSrc);
      alt && setImgAlt(alt);
    } else if (!correct && incorrectSrc) {
      setImgSrc(incorrectSrc);
    }

    let found = false;

    do {
      found = true;

      setTimeout(() => {
        if (quizRef.current) {
          const yOffset = -100; 
          const y = quizRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;

          gsap.to(window, { scrollTo: { y }})
        }
      }, 200);
    } while (quizRef.current === null && !found)

    if ((qIndex + 1) === questions.length && correct && !results) {
      setUnlocked(true);
      setGates && gates && setGates([...gates, trigger]);

      if (onlyNow && nowTrigger) {
        setGates && gates && setGates([...gates, nowTrigger]);
      }

      if (onlyHours && hoursTrigger) {
        setGates && gates && setGates([...gates, hoursTrigger]);
      }
    }
  }

  const buildScenario = () => {
    const { scenario } = currQuestion || {};
    return scenario && <SpeechBox small pointerVariant="bottom">
      {scenario}
    </SpeechBox>
  }

  const buildImage = () => {
    if (imageDisplay === 'contain') {
      return (imgSrc) && <Image src={imgSrc} alt={imgAlt} classNames="content contained-quiz-img" />;
    } else {
      return (imgSrc) && <Image src={imgSrc} alt={imgAlt} />;
    }
  }

  const buildHeading = () => {
    const { heading } = currQuestion || {};
    return heading && <h2 className="text-center above-box">{heading}</h2>;
  }

  const buildQuestion = () => {
    const { question } = currQuestion || {};
    return question && <ContentBox>
      {question}
    </ContentBox>;
  }

  const buildOptions = () => {
    const { options } = currQuestion || {};
    const { submitted } = submission;
    
    const optionsHTML = options && options.map((o: Option, i: number) => {
      const { option } = o;
      const selected = selectedIndexes.includes(i);

      return <div key={i} className={`option ${selected ? 'active' : ''} ${submitted ? 'disabled' : ''} ${stackedButtons ? 'stacked' : ''}`} onClick={() => !submitted && handleOptionClick(i)}>
        <div />
        <div>
          {option}
        </div>
      </div>;
    });

    return <div className={`options ${buttonDisplay}`}>
      {optionsHTML}
    </div>
  }

  const buildButton = () => {
    const { submitted, completed, correct } = submission;
    const nextText = qIndex === questions.length - 1 ? 'Finish' : `Let's Continue`;
    
    if (submitted && !results && qIndex === questions.length - 1 && correct) return <button onClick={handleResetQuiz}>Reset</button>;
    if (submitted && correct) return <button onClick={handleNextQuestion}>{nextText}</button>;
    if (submitted && !correct) return <button onClick={handleResetQuestion}>Try Again</button>;
    if (completed) return <button onClick={handleResetQuiz}>Reset</button>;
    return <button disabled={selectedIndexes.length === 0} onClick={handleSubmission}>Submit</button>;
  }
  
  const buildFeedback = () => {
    const { correct: correctAnswer } = submission;
    const { feedback } = currQuestion || {};
    const { generic, correct, incorrect } = feedback || {};
    let fb = generic;

    if (correct  && correctAnswer) fb = correct;
    if (incorrect && !correctAnswer) fb = incorrect;

    return <div ref={fbRef}>
      <ContentBox>
        {/* {submitted && correctAnswer && <img className="fb-icon" src={correctIcon} alt="correct" />}
        {submitted && !correctAnswer && <img className="fb-icon" src={incorrectIcon} alt="incorrect" />} */}
        {fb}
      </ContentBox>
    </div>;
  }

  const buildResults = () => {
    const { content } = results || {};
    return content && <ContentBox>
      {content}
      {buildButton()}
    </ContentBox>;
  }

  const { completed, submitted, correct } = submission;
  const correctClass = () => {
    if (correct) return 'correct';
    if (correct === false) return 'incorrect';
    return '';
  }

  return <div className="quiz" ref={quizRef}>
    {buildScenario()}
    {buildImage()}
    {buildHeading()}
    <>
      {!completed && (
        <>
          {!submitted && buildQuestion()}
          {submitted && buildFeedback()}
          <div className={`quiz-content content ${correctClass()}`}>
            {buildOptions()}
            {buildButton()}
          </div>
        </>
      )}
      {completed && (
        <div className="quiz-results">
          {buildResults()}
        </div>
      )}
    </>
  </div>
}