import React from 'react';
import ImgSrc1 from '../../../assets/images/illustrations/33-CoachSuperMegBlowingWhistle.png';
import ImgSrc2 from '../../../assets/images/illustrations/34-CoachSuperMegPointingToUser.png';
import ImgSrc3 from '../../../assets/images/illustrations/35-SuperMegUtilityBelt.png';
import ImgSrc4 from '../../../assets/images/illustrations/16-ChildHavingNumbingCreamApplied.png';
import ImgSrc5 from '../../../assets/images/illustrations/4-PointingToViewer.png';
import ImgSrc6 from '../../../assets/images/illustrations/36-SuperMegThinking.png';
import ImgSrc7 from '../../../assets/images/illustrations/17-ChildHavingBuzzyBeeApplied.png';
import ImgSrc8 from '../../../assets/images/illustrations/37-SuperMegIsVibrating.png';
import QuizQuestionImg from '../../../assets/images/illustrations/13-SuperMeg-6YearOldIsTalking.png';

import { ContentBoxWithImage, SpeechBoxWithImage, SpeechBox, VideoBox } from '../../molecules';
import { Quiz } from '../../organisms';
import { Belt } from '../../organisms/Belt';
import { BeltSlide } from '../../organisms/BeltSlide';
import { Accordions, TimeSkipWrap } from '../../organisms';

export const Section2: React.FC = () => {
  return <section id="section-2">
    <TimeSkipWrap gate="2">
      <ContentBoxWithImage
        src={ImgSrc1} 
        alt="SuperMeg is dressed as a coach"
        heading="I'VE GOT THIS!"
      >
        <p>I don’t know about you, but I don’t take on any supervillain without a game plan! Hard things are always easier when we know HOW we want to deal with it.</p>
        <p>We're going to make sure you've got a game plan for this. Armed with that, we all feel more comfortable and in control, and that works for everyone.</p>
        <p>I’m going to tell you about several super-strategies that can be used by themselves or combined.</p>
      </ContentBoxWithImage>

      <ContentBoxWithImage
        src={ImgSrc2} 
        alt="SuperMeg is dressed as a coach"
      >
        <p>You and your child get to CHOOSE what you want and what works best for you! Choice is a super power in itself, because when we make choices, we feel better and more in control.</p>
        <p>You mission (and you should definitely choose to accept it!) is to give your child as much choice and power as possible.</p>
        <p><b>As we teach you about the strategies, have your child CHOOSE ones they want to use.</b></p>
        <p>But I'm getting ahead of myself! Let’s start at the beginning, and learn about HOW pain works, and how you and kid can learn to take more control can be the boss of your brain!</p>
      </ContentBoxWithImage>

      <VideoBox heading="SHOW ME HOW" src="https://www.youtube.com/embed/iSKp-TMqN1M">
        <p>Wield the Warning Signal video.</p>
      </VideoBox>
    </TimeSkipWrap>

    <TimeSkipWrap gate="2" hours now>
      <SpeechBoxWithImage
        src={ImgSrc3} 
        alt="SuperMeg is pointing to her utility belt"
        heading="YOUR UTILITY BELT"
      >
        <h4>Fantastic! Now that you have that under your belt, let’s get you a much cooler belt! All of the coolest heroes have a utility belt full of tricks to help them out under pressure.</h4>
        <h4>You won't find any grappling hooks on my utility belt. In each of these pockets is a tool or strategy that can help you help your child be more calm, more comfortable, and more in control!</h4>
        <h4>Let’s take a look…</h4>
      </SpeechBoxWithImage>

      <Belt heading="EXPLORE YOUR TOOLS">
        <BeltSlide
          heading="Topical Anesthetics (AKA Numbing Cream)"
          imgSrc={ImgSrc4}
          imgAlt="Child having numbing cream applied"
          videoSrc={'https://www.youtube.com/embed/YrnSUJWU_30'}
          videoFullWidth
          accordions={[{
            heading: <p>What is it?</p>,
            body: <p>Topical anesthetics are usually creams or sprays you put on the surface on the skin to make it numb, so the poke isn't felt as much. They can be prescribed by your doctor or bought over the counter at a drug store, pharmacy, or online. Topical anesthetics often remain effective for a pretty long time, but they can take a little while to take effect so it’s best to put them on early. </p>
          }, {
            heading: <p>How do I prepare?</p>,
            body: <p>Get some beforehand, either from the doctor or buy it yourself. Some creams have to be applied beforehand - usually 20-60 minutes before to get to full effectiveness. You should plan to put them on at home or in the waiting room.</p>
          }]}
        />
      </Belt>

      <ContentBoxWithImage
        src={ImgSrc5} 
        alt="SuperMeg is pointing at the user"
        heading="HOW CAN I GET IT?"
      >
        <p>Though it is one of the strongest pain management recommendations from all the experts, it often goes overlooked and unused. Best practice guidelines recommend that numbing cream should ALWAYS be used for needle pokes.</p>
        <p>As a Pain Champion, you'll almost always have to ask for it, or get it yourself. <b>So step up and ask for it</b>!</p>
        <p>Need some help on how to ask your providers to use topical anesthetics?</p>
        
        <Accordions contentAccordions content={[{
          heading: <p>Speak Up</p>,
          body: <>
            <SpeechBox pointerVariant="top" speakup>
              <h4>We’d like to use topical anesthetic, or numbing cream. What options do you have?</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>Where will the needle poke be? Can you show me where to put the numbing cream?</h4>
            </SpeechBox>
            <p>(if you already have your own and put it on at home)</p>
            <SpeechBox pointerVariant="top" speakup>
              <h4>We already put numbing cream on here and here. We’d like you to do the poke one of those places.</h4>
            </SpeechBox>

            <p>Common pitfall: Sometimes providers have heard topicals can causes vaso-constriction (veins to get smaller) or other issues. Research has clearly established this isn’t true.</p>
            <p>You may have to say:</p>
            <SpeechBox pointerVariant="top" speakup>
              <h4>Actually, using topical anesthetics with every needle poke is recommended by the best practice guidelines of the World Health Organization. I really want my child to have it.</h4>
            </SpeechBox>
          </>
        }]} />

        <p>It’s also pretty easy to get it yourself. You can get it over the counter at a drug store, or online. Here’s a list of some common types you can get, with simple guidelines on how to use them.</p>
        <p>
          <a href="https://static1.squarespace.com/static/5a1e4091f09ca4cdefdc0572/t/5f72a8f9c5d4692dc9fa55c4/1601349881530/Topical+anesthetics+guide+draft+1.pdf" rel="noopener noreferrer" target="_blank">List of Topical Anesthetics</a>
        </p>
        <h4 className="no-mb">Quick Tips!</h4>
        <ul>
          <li>Plan ahead: you'll need to put it on beforehand. You can do it at home, in the waiting room, anywhere! It lasts a really long time, so don’t worry about it being “too early.”</li>
          <li>Put it anywhere your child MIGHT need a needle poke. It’s okay to put in multiple spots just to be safe.</li>
          <li>Let your child know what it’s for, so they can feel more comfortable knowing it’s there to help! Check out some powerful ways to do that here.</li>
        </ul>
        <p>Words matter! What we say to kids while using topical anesthetics is important to helping them be calm and comfortable. Click here some powerful phrases you can use.</p>
        
        <Accordions contentAccordions content={[{
          heading: <p>Comfort Talk</p>,
          body: <>
            <SpeechBox pointerVariant="top" speakup>
              <h4>This is a great that we have a way to help you be more comfortable!</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>This spray/cream will help you to not be bothered.</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>It’ll numb the nerves so that they can’t carry ouch signals.</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>This is a big helper so we can get the poke/procedure done easily and quickly.</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>As the spray/cream goes on you’ll notice how good the cooling (spray) or soothing (cream) feels.</h4>
            </SpeechBox>
          </>
        }]} />
      </ContentBoxWithImage>

      <ContentBoxWithImage
        src={ImgSrc6} 
        alt="SuperMeg is thinking"
        heading="WHAT IF I CAN'T GET IT?"
      >
        <p>If for some reason, you can’t get any numbing cream, have no fear! There are other options that work.</p>
        <p>Numbing cream blocks the pain sensation from getting to the pain centers in our brain, but there are other ways to do that too.</p>
        <p>We have more tools in our utility belt, so let’s check them out!</p>
      </ContentBoxWithImage>

      <Belt heading="PLEASE SHOW ME MORE TOOLS">
        <BeltSlide
          heading="Vibration"
          imgSrc={ImgSrc7}
          imgAlt="Child having Buzzy Bee applied"
          videoSrc={'https://www.youtube.com/embed/gsj5Mjlmm5k'}
          videoFullWidth
          accordions={[{
            heading: <p>What is it?</p>,
            body: <p>Another way to block pain signals from getting to the brain is creating a kind of sensation traffic jam. We can use a sensation we like to block out an uncomfortable feeling. It might sound confusing, but it's actually really simple. There are things like a Buzzy Bee (as seen above) that create a vibration sensation (heh-heh... that rhymes) on the skin. This interrupts pain signals when it is placed between the location of the needle poke and the brain.</p>
          }, {
            heading: <p>How do I prepare?</p>,
            body: <p>Be sure to demonstrate it to your child beforehand and even let them play with it, so it's fun and interesting! It often helps to show them how the vibration changes the way things feels by demo’ing with something like a paper clip or pen cap lightly scratching their skin.</p>
          }]}
        />
      </Belt>

      <ContentBoxWithImage
        src={ImgSrc8} 
        alt="SuperMeg is vibrating"
        heading="HOW CAN I GET IT?"
      >
        <p>You can ask your medical provider if they have a Buzzy Bee available, and if not, you can order one online if you have the time.</p>
        <p>Click <b><a href="https://buzzyhelps.com/products/buzzy%C2%AE-mini-personal?gclid=EAIaIQobChMI2sbZus2v7AIVEdbACh1pIwN5EAAYASAAEgIoufD_BwE" rel="noopener noreferrer" target="_blank">HERE</a></b> to order your own.</p>
        <h4 className="no-mb">Quick Tips!</h4>
        <ul>
          <li>Let the child play with it and experience the sensation to it’s interesting instead of surprising or scary.</li>
          <li>Place it on the skin with pressure 30 seconds or so before the poke.</li>
          <li>Place it as close to the poke as you can, and make sure it’s between the brain and the poke.</li>
          <li>You can practice how it works beforehand to increase their confidence.</li>
        </ul>

        <Accordions contentAccordions content={[{
          heading: <p>Comfort Talk</p>,
          body: <>
            <SpeechBox pointerVariant="top" speakup>
              <h4>Isn’t it great that we have Buzzy Bee to help us get through this procedure?</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>When we place buzzy close to the poke it’ll confuse the nerves in a really good way so that you won’t be bothered.</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>Buzzy will tickle you/this skin and it can feel kind of silly and weird, and that helps big time!</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>With Buzzy, you may not even notice the poke at all!</h4>
            </SpeechBox>
          </>
        }]} />
      </ContentBoxWithImage>

      <Quiz
        trigger="3"
        buttonDisplay="row"
        questions={[{
          src: QuizQuestionImg,
          alt: 'SuperMeg talking pose',
          heading: "LET'S GET THIS STRAIGHT",
          question: <p>It really helps to practice using vibration before the poke so kids can get familiar with the vibration feeling.</p>,
          options: [{
            option: <p>True</p>,
            correct: true,
          }, {
            option: <p>False</p>,
          }],
          feedback: {
            generic: <>
              <p><b>Believe it or not, vibration can block pain signals from getting to the brain by creating a traffic jam that blocks distressing sensations!</b></p>
            </>,
          },
        }]}
      />
    </TimeSkipWrap>
  </section>;
}