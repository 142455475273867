import React, { useContext } from 'react';
import { TimeContext } from '../../../App';

interface Props {
  children: React.ReactNode;
  hours?: boolean;
  now?: boolean;
  gate: string;
};

export const TimeSkipWrap = ({ children, hours, now, gate }: Props) => {
  const { onlyHours, onlyNow, hasTime, gates } = useContext(TimeContext);
  if (gates && !gates.includes(gate)) return <div className="hidden">{children}</div>;
  if (onlyHours && hours) return <>{children}</>;
  if (onlyNow && now) return <>{children}</>;
  if ((onlyHours && !hours && !hasTime)) return <div className="hidden">{children}</div>;
  if ((onlyNow && !now && !hasTime)) return <div className="hidden">{children}</div>;
  return <>{children}</>;
}