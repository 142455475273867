import React from 'react';
import ImgSrc1 from '../../../assets/images/illustrations/35-SuperMegUtilityBelt.png';
import ImgSrc2 from '../../../assets/images/illustrations/4-PointingToViewer.png';
import ImgSrc3 from '../../../assets/images/illustrations/12-SuperMegBecomes6YearsOld.png';
import ImgSrc4 from '../../../assets/images/illustrations/42-SuperMegHoldingShield.png';
import ImgSrc5 from '../../../assets/images/illustrations/43-SuperMegHoldingShieldTalkingToUser.png';
import ImgSrc6 from '../../../assets/images/illustrations/3-SuperMegAndFoundationTeam.png';
import ImgSrc7 from '../../../assets/images/illustrations/44-ComicCover2.png';
import ImgSrc35 from '../../../assets/images/illustrations/35-SuperMegUtilityBelt.png';

import { ContentBoxWithImage, SpeechBoxWithImage, VideoBox, ContentBox, Trigger, SpeechBox } from '../../molecules';
import QuizQuestionImg from '../../../assets/images/illustrations/13-SuperMeg-6YearOldIsTalking.png';
import QuizCorrectFbImg from '../../../assets/images/illustrations/14-SuperMeg-6YearOldIsHappy.png';
import QuizIncorrectFbImg from '../../../assets/images/illustrations/15-SuperMeg-6YearOldIsUpset.png';
import QuizCorrectFbImg2 from '../../../assets/images/illustrations/35-SuperMegUtilityBelt.png';
import QuizIncorrectFbImg2 from '../../../assets/images/illustrations/39-SuperMegLookingBossy.png';
import QuizCorrectFbImg3 from '../../../assets/images/illustrations/38-SuperMegHasAnIdea.png';
import QuizIncorrectFbImg3 from '../../../assets/images/illustrations/39-SuperMegLookingBossy.png';
import { Accordions, Quiz, TimeSkipWrap } from '../../organisms';

import Quiz1 from '../../../assets/images/illustrations/21-ComfortPosition-BackToChest(Lap).png';
import Quiz2 from '../../../assets/images/illustrations/20-ComfortPostion-ChestToChest.png';
import Quiz3 from '../../../assets/images/illustrations/24-ComfortPosition-IncorrectPosition.png';
import Quiz4 from '../../../assets/images/illustrations/23-ComfortPosition-SidewayLapSit.png';

import Quiz6 from '../../../assets/images/illustrations/29-ChildWaiting(w_Bubbles).png';
import Quiz7 from '../../../assets/images/illustrations/30-ChildWaiting(w_oBook).png';

import QuizFB6 from '../../../assets/images/illustrations/28-ChildWaiting(w_oBubbles).png';
import QuizFB7 from '../../../assets/images/illustrations/31-ChildWaiting(w_Book).png';

import Crying from '../../../assets/images/illustrations/46-6YearOldSuperMegIsCrying.png';
import Defiant from '../../../assets/images/illustrations/47-6YearOldSuperMegIsDefiant.png';
import IceCream from '../../../assets/images/illustrations/48-6YearOldSuperMegIsHappyHasIceCream.png';
import Writing from '../../../assets/images/illustrations/45-6YearOldSuperMegWritingCopingPlan.png';
import Talking from '../../../assets/images/illustrations/9-SuperMegSittingAndTalkingOnPhone.png';
import Talking2 from '../../../assets/images/illustrations/49-SuperMegGenericTalkingPose.png';
import Talking3 from '../../../assets/images/illustrations/47-6YearOldSuperMegIsDefiant.png';


export const Section4: React.FC = () => {
  return <section id="section-4">
    <TimeSkipWrap gate="6" hours>
      <SpeechBoxWithImage
        src={ImgSrc1} 
        alt="SuperMeg is pointing to her utility belt"
        heading="Congratulations!"
      >
        <h4><b>You've earned your utility belt!</b></h4>
        <h4>You now have so many ways to be the Pain Champion your child needs you to be. You're empowered to use your voice to get what is best for your child!</h4>
        <h4>Armed with this tool belt, and your natural parent intuition power, YOU are the most powerful source of comfort your child has! It's time to create a game plan, and then you can earn your pain shield.</h4>
      </SpeechBoxWithImage>

      <ContentBox
        topBuffer
        heading="WHAT'S A GAME PLAN?"
      >
        <p>A game plan is a written plan of strategies your child wants to use during their medical procedure. Giving kids the power to CHOOSE as much as possible helps them have a sense of control, and gives them a chance to prepare.</p>
        <p>A game plan is important because everyone (kids, parents, and providers) feels better when they know what is going to happen next. Sometimes it is hard to remember things in the moment, so writing things down is really good idea. It also feels reassuring to have something to hold onto. You can be confident that everything we’ve shown you is in line with the recommendations of every major pediatric organization in the world!</p>
        <p>We’ve got your back!</p>
        <p>To have making that plan be as easy as possible, we put together this fun little piece of tech that puts the power in the hands your child…literally! In just a few minutes, our SuperMeg comfort plan builder will guide your child to create their very own plan for making any needle poke a lot more comfortable, and a lot less scary! After they complete it, we will send you an email with the personalized plan and all the resources you need to make it happen. You can be confident that it is based on the latest research and best practice guidelines!</p>
        <button className="green"><a href="http://supermeg.comfortquest.io/" rel="noopener noreferrer" target="_blank">Get Started</a></button>
      </ContentBox>

      <VideoBox src="https://www.youtube.com/embed/UbK9FFoAcvs">
        <p>This is a great video for your child to learn all the different ways they can help themselves during a poke. It teaches them to be the Boss of their Brain!</p>
      </VideoBox>

      <ContentBoxWithImage
        src={ImgSrc2} 
        alt="SuperMeg is holding one finger up to the user"
      >
        <p>These tools and the game plan will get you and your child through the procedure, but your role as a Pain Champion isn't over just yet!</p>
        <p>One the of more important parts of how a child feels about their experience, and how they feel about getting pokes in the future is determined by what we say and how parents act right afterwards.</p>
      </ContentBoxWithImage>

      <Trigger trigger="7" />
    </TimeSkipWrap>

    <TimeSkipWrap gate="7">
      <VideoBox heading="THE POKE IS OVER BUT WE AREN'T QUITE DONE YET!" src="https://www.youtube.com/embed/lvCabG6_ekI">
        <p>Watch the Framing The Story video.</p>
      </VideoBox>
    </TimeSkipWrap>

    <TimeSkipWrap gate="7" hours>
      <ContentBoxWithImage
        src={ImgSrc3} 
        alt="SuperMeg becomes 6 years old."
        heading="HOW WOULD YOU RESPOND?"
      >
        <p>Let's do another quick role-play. The last one was so fun!</p>
        <p>I'll play your child just after they've received their needle, and you play my parent.</p>
        <p><b>Let's give it a go! </b></p>
      </ContentBoxWithImage>

      <Quiz
        trigger="8"
        hoursTrigger="14"
        questions={[{
          scenario: <h4>*crying*</h4>,
          src: Crying,
          alt: 'SuperMeg (6 years old) is crying',
          heading: "1 of 3",
          question: <p>How would you respond?</p>,
          options: [{
            option: <p>I'm so sorry you had to go through this. I promise, no more needles for a long time!</p>
          }, {
            option: <p>(Hug and comfort them) You did it! It was hard, and you did it anyway. I’m so proud of you. You did a great job of coping and it’s going to be even easier next time. (hug some more…can’t get enough hugs!)</p>,
            correct: true,
          }],
          feedback: {
            generic: <>
              <p>It’s really tempting, but we shouldn’t apologize for taking care of our kid’s health. The unintended message is that we see it as an inevitably bad experience, when really, it can be positive and no big deal. We also never want to promise something (like no needles) when we actually don’t know what will happen.</p>
              <p>If you speak positively about what happened and point out things (even the smallest of things!) they did well, your child is more likely to remember this as a positive experience and have better expectations for the future.</p>
            </>,
            incorrectSrc: QuizIncorrectFbImg,
            correctSrc: Defiant,
            alt: '',
          },
        }, {
          scenario: <><h4>There! I did it!</h4><h4>Proud of me!?</h4></>,
          src: QuizCorrectFbImg,
          alt: 'SuperMeg (6 years old) is giving a thumbs up to the user',
          heading: "2 of 3",
          question: <p>How would you respond?</p>,
          options: [{
            option: <p>I’m so proud of you! You did a great job! Your special skills really helped you feel better. It’s so great to know that for next time so things can always get even better. That was incredible! Let’s go get a reward to celebrate your awesome efforts! (Reward her with an ice cream or a toy to make her feel super special)</p>,
            correct: true,
          }, {
            option: <p>Reward her with an ice cream or a toy to make her feel super special.</p>,
            correct: true,
          }],
          feedback: {
            generic: <>
              <p>OK... so I kind of rigged that one to make sure I'd get ice cream. But honestly, a treat is a great way to reinforce how awesome it is that your kid used their skills to help themselves!</p>
              <p>Your superpower here is helping chose what they focus on, and what they remember. You highlight the good stuff…ANYTHING that went better…and they get to feel good about what happened and more positive about what happens next time. It is important to be very specific about the skills they used that helped them. For example: "Great breathing!" and "Watching that video really seemed to help!" They will see it as a skill they can get better at and use again. The ice cream or whatever they choose isn't a bribe... it is a reward for working hard, being brave, and doing hard things.</p>
            </>,
            incorrectSrc: QuizIncorrectFbImg,
            correctSrc: IceCream,
            alt: '',
          },
        }, {
          scenario: <h4>Nope, nope nope! I will not get my shot.</h4>,
          src: Defiant,
          alt: 'SuperMeg (6 years old) has her arms folded',
          heading: "3 of 3",
          question: <p>How would you respond?</p>,
          options: [{
            option: <p>You got so close...nicely done! I'm proud of you for trying so hard, and you did a great job coping! Now we can practice the things that went well, change up our plan and practice for the things that were harder, and next time it will be even easier.</p>,
            correct: true,
          }, {
            option: <p>Great, now we're going to have to come back and do it all again!</p>,
          }],
          feedback: {
            generic: <>
              <p>Sometimes, despite everyone’s best efforts, it isn’t going to happen. That’s okay (there is always next time) but it’s really important how you handle that to make sure we are setting up for future success.</p>
              <p>Not only does a bad reaction add to an already anxious situation, but angrily reminding them that they have to do it again will ensure they dread coming back for another attempt. It's much better to frame this attempt as a positive step forward, review what you both learned, and to let your kid know that it has helped make next time easier.</p>
              <p>If it really didn't work well...</p>
              <p>While most families can be really successful with these strategies, there are some kids who have so much anxiety or previous bad experiences, they would benefit from help of a mental health professional to help them overcome their fear. You can always ask your doctor for recommendations. Trust your parent gut!</p>
            </>,
            incorrectSrc: QuizIncorrectFbImg,
            correctSrc: Defiant,
            alt: '',
          },
        }]}
        results={{
          src: Talking3,
          alt: 'SuperMeg is giving advice',
          content: <>
            <h4>FRAMING IT WITH PRAISE</h4>
            <p>Speaking positively about what happened and pointing out things (even the smallest of things!) will help your child to remember this as a positive experience and have better expectations for the future.</p>
            <p>Want to see a list of things that could praise them for?</p>
            <Accordions contentAccordions content={[{
              heading: <p>Comfort Talk</p>,
              body: <>
                <SpeechBox pointerVariant="top" speakup>
                  <h4>You did so great at (provide something specific, like sitting still/coming into the room/using your skills/sitting on my lap/using your breath/etc) I’m very proud of you!</h4>
                </SpeechBox>
                <SpeechBox pointerVariant="top" speakup>
                  <h4>You’re learning so quickly how to help yourself through these pokes and getting better and better each time.</h4>
                </SpeechBox>
                <SpeechBox pointerVariant="top" speakup>
                  <h4>Now that you’ve done it so well, remember it’ll be even easier next time. You’ve got it!</h4>
                </SpeechBox>
                <SpeechBox pointerVariant="top" speakup>
                  <h4>I know that was tough, and I’m so proud of you for trying so hard.</h4>
                </SpeechBox>
                <SpeechBox pointerVariant="top" speakup>
                  <h4>If it didn’t go as well as hoped, still find something to praise!</h4>
                </SpeechBox>
              </>
            }]} />
          </>
        }}
      />
    </TimeSkipWrap>

    <TimeSkipWrap gate="8">
      <SpeechBoxWithImage
        src={ImgSrc4} 
        alt="SuperMeg is holding the Pain Shield"
        heading="WHERE'S MY SHIELD?"
      >
        <h4>Ah yes, there's just the small matter of handing over the Pain Shield to its rightful owner...</h4>
        <h4>Before I do, let's make sure you're all over this. Let's take everything we've covered and run through the needle experience from start to finish. Then, this shiny, impeccably designed shield is all yours.</h4>
      </SpeechBoxWithImage>

      <Quiz
        trigger="9"
        multi
        questions={[{
          src: Talking,
          alt: 'Calendar with appointment circled',
          heading: "LET'S DO THIS!",
          question: <>
            <p>You have an appointment booked for your child to get an immunization.</p>
            <p>What can you do before the appointment to ensure your medical providers are familiar with the current research and the guidelines for pain minimization?</p>
          </>,
          options: [{
            option: <p>Make a call</p>,
            correct: true,
          }, {
            option: <p>Send a helpful email</p>,
            correct: true,
          }, {
            option: <p>Nothing except wait and see what they do</p>,
          }],
          feedback: {
            correct: <>
              <h4>That's Right!</h4>
              <p>For many reasons, and despite their best intentions, some medical providers don't know the best pain management practices for kids. As a Pain Champion, you might need to step up and ask for what you want and need. Call ahead of your appointment to ask about what they do for pain minimization, and we can help support you and them by sending a helpful email to your provider with the latest research and guidelines.</p>
              <p>For some people, that can feel a little weird, but you can rest assured it’s what is best for your child. That's a mission that any good medical provider can get behind!</p>
            </>,
            incorrect: <>
              <h4>That's Not Right!</h4>
              <p>For many reasons, and despite their best intentions, some medical providers don't know the best pain management practices for kids. As a Pain Champion, you might need to step up and ask for what you want and need. Call ahead of your appointment to ask about what they do for pain minimization, and we can help support you and them by sending a helpful email to your provider with the latest research and guidelines.</p>
              <p>For some people, that can feel a little weird, but you can rest assured it’s what is best for your child. That's a mission that any good medical provider can get behind!</p>
            </>,
            incorrectSrc: QuizIncorrectFbImg3,
            correctSrc: QuizCorrectFbImg3,
            alt: '',
          },
        }]}
      />
    </TimeSkipWrap>
    
    <TimeSkipWrap gate="9">
      <Quiz
        trigger="10"
        questions={[{
          scenario: <h4>Am I getting a needle when I go to the doctor?</h4>,
          src: QuizQuestionImg,
          alt: 'SuperMeg (6 years old) talking pose',
          heading: "1 of 2",
          question: <p>How would you respond?</p>,
          options: [{
            option: <p>No, sweetie. That won't happen at all.</p>
          }, {
            option: <p>I’m not totally sure but I think so. The good news is that there are a lot of ways we can make a poke no big deal, and you can feel comfortable and in control. Let’s work on our plan together!”</p>,
            correct: true,
          }],
          feedback: {
            generic: <>
              <p>Not telling me about a poke might make me feel better in the moment, but I'll be in for a pretty big shock when we go to the doctors, and I’m more likely to get upset and have a hard time coping. It also might make it hard to trust you, and that feels scary.</p>
              <p>It's much better to be honest from the get-go so we can plan ahead together. EVERYONE feels better about doing hard things when we have a plan on how to tackle it! It also means we can decide HOW to cope, and practice the skills.</p>
            </>,
            incorrectSrc: QuizIncorrectFbImg,
            correctSrc: Writing,
            alt: 'SuperMeg (6 years old) looks confident',
          },
        }, {
          scenario: <h4>Will it hurt?</h4>,
          src: QuizQuestionImg,
          alt: 'SuperMeg (6 years old) talking pose',
          heading: "2 of 2",
          question: <p>How would you respond?</p>,
          options: [{
            option: <p>No, it’s not going to hurt. Just try not to cry.</p>
          }, {
            option: <p>You may feel pressure, squeezing or you might not notice anything at all. It might bother you a little bit, or it might not bother you at all. There are a lot of ways we can make it more comfortable.</p>,
            correct: true,
          }],
          feedback: {
            generic: <>
              <p>Open and honest communication is good, and lying to kids just teaches them not to trust you, which makes them more scared and nervous. We can't always promise a pain free experience, but we can promise ways to make it more comfortable. Also, telling kids not to cry tells kids both 1) their feelings aren’t going to be accepted and somehow “bad” and 2) it’s very likely there is something to cry about.</p>
              <p>Using words such as 'poke' and 'bother' as opposed to 'sting' or 'hurt' lessen anxiety and feel a lot less scary. It helps kids think about experiencing a poke differently and more positively, and opens up possibilities for using skills to cope and make it “no big deal.”</p>
            </>,
            incorrectSrc: QuizIncorrectFbImg,
            correctSrc: QuizCorrectFbImg,
            alt: 'SuperMeg (6 years old) looks confident',
          },
        }]}
      />
    </TimeSkipWrap>

    <TimeSkipWrap gate="10">
      <Quiz
        trigger="11"
        multi
        questions={[{
          src: ImgSrc35,
          alt: 'SuperMeg is wearing her utility belt',
          heading: "LET'S DO THIS!",
          question: <p>What options for changing the warning signal and increasing comfort do you have on your utility belt?</p>,
          options: [{
            option: <p>Comfort positioning</p>,
            correct: true,
          }, {
            option: <p>Breathing</p>,
            correct: true,
          }, {
            option: <p>Cold spray and vibration</p>,
            correct: true,
          }, {
            option: <p>Distraction</p>,
            correct: true,
          }, {
            option: <p>Lazer beams</p>,
          }, {
            option: <p>Topical anesthetics</p>,
            correct: true,
          }],
          feedback: {
            correct: <>
              <h4>That's Right!</h4>
              <p>In each of these pockets is a tool or strategy that can help you and your child become boss of your own brains and feel calm, comfortable, and in control! Pretty neat, huh?</p>
              <p>You’ve got all this:</p>
              <ul>
                <li>Topical anesthetics (numbing creams)</li>
                <li>Comfort positioning</li>
                <li>Distraction</li>
                <li>Breathing</li>
                <li>Cold spray and vibration</li>
              </ul>
            </>,
            incorrect: <>
              <h4>That's Not Right!</h4>
              <p>In each of these pockets is a tool or strategy that can help you and your child become boss of your own brains and feel calm, comfortable, and in control! Pretty neat, huh?</p>
              <p>You’ve got all this:</p>
              <ul>
                <li>Topical anesthetics (numbing creams)</li>
                <li>Comfort positioning</li>
                <li>Distraction</li>
                <li>Breathing</li>
                <li>Cold spray and vibration</li>
              </ul>
            </>,
            incorrectSrc: QuizIncorrectFbImg2,
            correctSrc: QuizCorrectFbImg2,
            alt: '',
          },
        }]}
      />
    </TimeSkipWrap>

    <TimeSkipWrap gate="11">
      <Quiz
        trigger="12"
        buttonDisplay="row"
        questions={[{
          src: Talking2,
          alt: 'SuperMeg talking pose',
          heading: "LET'S GET THIS STRAIGHT",
          question: <p>It really helps to practice using vibration before the poke so kids can get familiar with the vibration feeling.</p>,
          options: [{
            option: <p>True</p>,
            correct: true,
          }, {
            option: <p>False</p>,
          }],
          feedback: {
            generic: <>
              <p><b>Believe it or not, vibration can block pain signals from getting to the brain by creating a traffic jam that blocks distressing sensations!</b></p>
            </>,
          },
        }]}
      />
    </TimeSkipWrap>

    <TimeSkipWrap gate="12">
      <Quiz
        trigger="13"
        questions={[{
          src: Quiz6,
          alt: 'Sarah is happily playing with a toy. (Two states: with and without toy)',
          heading: "1 of 2",
          question: <>
            <p>Sarah is happily playing while waiting, but would like to watch as the poke happens. The nurse tells her not to look, but she says she wants to see it.</p>
            <p><b>Which do you give her first?</b></p>
          </>,
          options: [{
            option: <p>Book</p>,
          }, {
            option: <p>Nothing</p>,
            correct: true,
          }, {
            option: <p>Device</p>,
          }],
          feedback: {
            incorrect: <>
              <p>That's not quite right. Try again.</p>
            </>,
            correct: <>
              <p>Some children may feel more in control by watching the poke, and that's fine too! Giving the kids the choice of watching or not is another way to make them feel powerful. If a provider tries to tell your child not to watch, just gently let them know that it helps your child to look, and praise your child for knowing what is best for them.</p>
            </>,
            correctSrc: QuizFB6,
          },
        }, {
          src: Quiz7,
          alt: 'Sarah is happily playing with a toy. (Two states: with and without toy)',
          heading: "2 of 2",
          question: <>
            <p>Frank keeps saying he doesn't want to watch the poke and is visibly anxious. You don't have any devices to offer him and are scrambling to find something to distract him.</p>
            <p><b>Which do you give him first?</b></p>
          </>,
          options: [{
            option: <p>Book</p>,
            correct: true,
          }, {
            option: <p>Nothing</p>,
          }, {
            option: <p>Device</p>,
          }],
          feedback: {
            incorrect: <>
              <p>That's not quite right. Try again.</p>
            </>,
            correct: <>
              <p>Great choice! Books and toys from home (or the doctor’s office) are a simple way to distract your child while they wait for their needle or procedure. Singing songs or telling stories work too! It’s really about giving them something else to focus on.</p>
            </>,
            correctSrc: QuizFB7,
          },
        }]}
      />
    </TimeSkipWrap>

    <TimeSkipWrap gate="13">
      <Quiz
        trigger="14"
        questions={[{
          scenario: <h4>Nope, nope nope! I will not get my shot.</h4>,
          src: Defiant,
          alt: 'SuperMeg (6 years old) has her arms folded',
          heading: "1 of 2",
          question: <p>How would you respond?</p>,
          options: [{
            option: <p>You got so close...nicely done! I'm proud of you for trying so hard, and you did a great job coping! Now we can practice the things that went well, change up our plan and practice for the things that were harder, and next time it will be even easier.</p>,
            correct: true,
          }, {
            option: <p>Great, now we're going to have to come back and do it all again!</p>,
          }],
          feedback: {
            generic: <>
              <p>Sometimes, despite everyone’s best efforts, it isn’t going to happen. That’s okay (there is always next time) but it’s really important how you handle that to make sure we are setting up for future success.</p>
              <p>Not only does a bad reaction add to an already anxious situation, but angrily reminding them that they have to do it again will ensure they dread coming back for another attempt. It's much better to frame this attempt as a positive step forward, review what you both learned, and to let your kid know that it has helped make next time easier.</p>
              <p>If it really didn't work well...</p>
              <p>While most families can be really successful with these strategies, there are some kids who have so much anxiety or previous bad experiences, they would benefit from help of a mental health professional to help them overcome their fear. You can always ask your doctor for recommendations. Trust your parent gut!</p>
            </>,
            incorrectSrc: QuizIncorrectFbImg,
            correctSrc: Defiant,
            alt: '',
          },
        }, {
          scenario: <h4>*crying*</h4>,
          src: Crying,
          alt: 'SuperMeg (6 years old) is crying',
          heading: "2 of 2",
          question: <p>How would you respond?</p>,
          options: [{
            option: <p>I'm so sorry you had to go through this. I promise, no more needles for a long time!</p>
          }, {
            option: <p>(Hug and comfort them) You did it! It was hard, and you did it anyway. I’m so proud of you. You did a great job of coping and it’s going to be even easier next time. (hug some more…can’t get enough hugs!)</p>,
            correct: true,
          }],
          feedback: {
            generic: <>
              <p>It’s really tempting, but we shouldn’t apologize for taking care of our kid’s health. The unintended message is that we see it as an inevitably bad experience, when really, it can be positive and no big deal. We also never want to promise something (like no needles) when we actually don’t know what will happen.</p>
              <p>If you speak positively about what happened and point out things (even the smallest of things!) they did well, your child is more likely to remember this as a positive experience and have better expectations for the future.</p>
            </>,
            incorrectSrc: QuizIncorrectFbImg,
            correctSrc: Defiant,
            alt: '',
          },
        }]}
      />
    </TimeSkipWrap>

    <TimeSkipWrap gate="14">
      <ContentBoxWithImage
        src={ImgSrc4} 
        alt="SuperMeg is holding the shield and pointing into the air"
        heading="CONGRATULATIONS!"
      >
        <p><b>Well done!</b></p>
        <p>You're officially a Pain Champion.</p>
        <p>You get a shield...</p>
        <p>You get a shield...</p>
        <p>YOU GET A SHIELD!</p>
      </ContentBoxWithImage>
      
      <ContentBoxWithImage
        src={ImgSrc5} 
        alt="SuperMeg is holding the shield and pointing at the user"
      >
        <p>Sorry, I got carried away. But how could I not? I'm just so dang proud of you!</p>
        <p>Remember, YOU are the most powerful source of comfort for your child! You know them best, and it’s a great time to follow that mom instinct. You're ready to speak up and use your GREATEST superpower, your voice to make sure they have the best experience possible!</p>
      </ContentBoxWithImage>
    </TimeSkipWrap>

    <TimeSkipWrap gate="14" hours now>
      <ContentBoxWithImage
        src={ImgSrc6} 
        alt="SuperMeg is with The Meg Foundation in hero poses"
      >
        <p>Remember, my friends and I are always here to back you up with any information you need.</p>
        <p>You can also explore our resources at <a href="https://www.megfoundationforpain.org/resources-home" rel="noopener noreferrer" target="_blank">megfoundationforpain.org</a> or email us at <a href="mailto:info@megfoundationforpain.org">info@megfoundationforpain.org</a>. Our mission is to do whatever it takes to help you feel more confident and in control!</p>
        <p>Now say it with me one last time...</p>
      </ContentBoxWithImage>
      
      <ContentBoxWithImage
        src={ImgSrc7}
        alt="Comic book cover"
        heading="I'VE GOT THIS!"
        topHeading
      >
        <p><b>SuperMeg and the Pain Champions</b></p>
        <button><a href="https://www.megfoundationforpain.org/">EXIT</a></button>
      </ContentBoxWithImage>
    </TimeSkipWrap>
  </section>;
}