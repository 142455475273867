import React from 'react';
import { Image } from '../../atoms/Images/Image';
import { SpeechBox } from '../SpeechBox';

interface Props {
  src: string;
  alt: string;
  heading?: string;
  children: React.ReactNode;
  boxVariant?: 'left' | 'right';
  pointerVariant?: 'top' | 'bottom';
  small?: boolean;
};

export const SpeechBoxWithImage = ({ src, alt, heading, children, boxVariant = 'left', pointerVariant = 'top', small }: Props) => {
  // const pointerSrc = pointerVariant === 'top' ? pointerTop : pointerBot;
  return <div className={`speech-box speech-box-with-image`}>
    {heading && <h2 className="text-center above-image">{heading}</h2>}
    <Image src={src} alt={alt} />
    <SpeechBox boxVariant={boxVariant} pointerVariant={pointerVariant} small={small}>
      {children}
    </SpeechBox>
  </div>;
}