import React, { createContext, useState, Dispatch, SetStateAction } from 'react'
import { Section1, Section2, Section3, Section4, Begin } from './components/sections'
import { Menu } from './components/organisms'
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

type ContextProps = { 
  hasTime: boolean,
  setHasTime: Dispatch<SetStateAction<boolean>>,
  onlyHours: boolean,
  setOnlyHours: Dispatch<SetStateAction<boolean>>,
  onlyNow: boolean,
  setOnlyNow: Dispatch<SetStateAction<boolean>>,
  gates: string[],
  setGates: Dispatch<SetStateAction<string[]>>,
};

export const TimeContext = createContext<Partial<ContextProps>>({});

export const App: React.FC = () => {
  const [gates, setGates] = useState<string[]>(["0"]);
  const [hasTime, setHasTime] = useState<boolean>(false);
  const [onlyHours, setOnlyHours] = useState<boolean>(false);
  const [onlyNow, setOnlyNow] = useState<boolean>(false);
  
  return (
    <TimeContext.Provider value={{
      hasTime,
      setHasTime,
      onlyHours,
      setOnlyHours,
      onlyNow,
      setOnlyNow,
      gates,
      setGates,
    }}>
      <Menu />
      <Begin />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
    </TimeContext.Provider>
  )
}
