import React from 'react';
import ImgSrc1 from '../../../assets/images/illustrations/38-SuperMegHasAnIdea.png';
import ImgSrc2b from '../../../assets/images/illustrations/17b-ChildHavingShotBlocker.png';
import ImgSrc3 from '../../../assets/images/illustrations/18-ChildHavingColdSprayApplied.png';
import ImgSrc4 from '../../../assets/images/illustrations/19-ChildBeingHeldByTheirParent.png';
import ImgSrc5 from '../../../assets/images/illustrations/39-SuperMegLookingBossy.png';

import Quiz1 from '../../../assets/images/illustrations/21-ComfortPosition-BackToChest(Lap).png';
import Quiz2 from '../../../assets/images/illustrations/20-ComfortPostion-ChestToChest.png';
import Quiz3 from '../../../assets/images/illustrations/24-ComfortPosition-IncorrectPosition.png';
import Quiz4 from '../../../assets/images/illustrations/23-ComfortPosition-SidewayLapSit.png';

import ImgSrc6 from '../../../assets/images/illustrations/25-ChildReadingInWaitingRoom.png';

import Quiz5 from '../../../assets/images/illustrations/26-ChildWaiting(w_oTablet).png';
import Quiz6 from '../../../assets/images/illustrations/29-ChildWaiting(w_Bubbles).png';
import Quiz7 from '../../../assets/images/illustrations/30-ChildWaiting(w_oBook).png';

import QuizFB5 from '../../../assets/images/illustrations/27-ChildWaiting(w_Tablet).png';
import QuizFB6 from '../../../assets/images/illustrations/28-ChildWaiting(w_oBubbles).png';
import QuizFB7 from '../../../assets/images/illustrations/31-ChildWaiting(w_Book).png';

import ImgSrc7 from '../../../assets/images/illustrations/32-ChildBreathingDeeply.png';
import ImgSrc8 from '../../../assets/images/illustrations/40-SuperMegBlowsPinwheel.png';
import ImgSrc9 from '../../../assets/images/illustrations/41-SuperMegBlowsBubbles.png';

import { ContentBoxWithImage, ContentBox, Trigger, SpeechBox } from '../../molecules';
import { Belt } from '../../organisms/Belt';
import { BeltSlide } from '../../organisms/BeltSlide';
import { Accordions, TimeSkipWrap } from '../../organisms';
import { Quiz } from '../../organisms';

export const Section3: React.FC = () => {
  return <section id="section-3">
    <TimeSkipWrap gate="3" hours now>
      <ContentBoxWithImage
        src={ImgSrc1} 
        alt="SuperMeg has an idea"
        heading="WHAT IF I DON'T HAVE TIME?"
      >
        <p><b>No time to get buzzy (or anything else that vibrates)? There are other ways to interrupt that signal.</b></p>
        <p>You can take that alcohol swab they use to sterilize the skin and rub it back and forth vigorously (but not too hard!) for about 30 seconds before the poke to get a similar effect.</p>
        <p>Or you can choose another tool! We have more tools in our utility belt to explore!</p>
      </ContentBoxWithImage>
    
      <Belt heading="PLEASE SHOW ME MORE TOOLS">
        <BeltSlide
          heading="ShotBlocker"
          imgSrc={ImgSrc2b}
          imgAlt="Child having shot blocker applied"
          videoSrc={null}
          accordions={[{
            heading: <p>What is it?</p>,
            body: <p>It is a small u-shaped piece of plastic that has little plastic nubs on one side that is placed directly on the skin. The needle goes in the middle of the u shape. The plastic nubs press gently but firmly into the skin, and confuse the nerves with other signals so the poke is a lot more comfortable.</p>
          }, {
            heading: <p>How do I prepare?</p>,
            body: <p>You can buy a ShotBlocker online, or you can ask if they have them at your doctor's office. You can clean it with an alcohol swab before using it. Like the other sensation changing techniques, have your little one play with it and practice beforehand so it's not surprising, but interesting. It's helpful to let them know how it works and how it can help.</p>
          }]}
        />
      </Belt>

      <Belt heading="PLEASE SHOW ME MORE TOOLS">
        <BeltSlide
          heading="Cold Spray"
          imgSrc={ImgSrc3}
          imgAlt="Child having cold spray applied"
          videoSrc={null}
          accordions={[{
            heading: <p>What is it?</p>,
            body: <p>Another common and accessible anesthetic is cold spray (otherwise known as vapocoolants for the science buffs out there). Like the name suggests, it's just a spray that numbs the skin quickly and temporarily using cold. Most places should have this on hand, but you’ll often have to ask for it. It’s really easy to get it yourself in almost any drugstore. </p>
          }, {
            heading: <p>How do I prepare?</p>,
            body: <p>It usually takes 10 seconds or less of spraying to get the numbing effect. It can be a but surprising or weird feeling sometimes, so be sure to practice beforehand to take away any surprise factor and show kids how it feels.</p>
          }]}
        />
      </Belt>

      <Belt heading="PLEASE SHOW ME MORE TOOLS">
        <BeltSlide
          heading="Comfort Positioning"
          imgSrc={ImgSrc4}
          imgAlt="Child being held by their parent"
          videoSrc={null}
          accordions={[{
            heading: <p>What is it?</p>,
            body: <p>One of the easiest and most powerful things you can do it use the power of touch and connection. There are some easy and effective ways to position your child during a poke or procedure so it can be done safely AND they feel safe and comforted. Being close to you helps your child feel secure and creates a helpful chemical change in their body that increases calm and comfort. Talk about a superpower! </p>
          }, {
            heading: <p>How do I prepare?</p>,
            body: <p>Check out the <b><a href="https://static1.squarespace.com/static/5a1e4091f09ca4cdefdc0572/t/5f736bcd27eee257e224f83c/1601399768355/Comfort+positioning+guide+v1.pdf" rel="noopener noreferrer" target="_blank">comfort position guide</a></b>, and practice with your child to see what works best for them and you!</p>
          }]}
        />
      </Belt>

      <ContentBoxWithImage
        src={ImgSrc5} 
        alt="SuperMeg is holding her hand up to the user, with a cranky face"
        heading="WHAT GETS IN THE WAY?"
      >
        <p>Although comfort positioning is one of the easiest strategies, it may receive pushback from certain medical providers. Some very wellintentioned providers may tell parents to hold the child down. This is a really bad idea, and can create lasting medical fears or even trauma. That may simply be how they were trained, but the research is conclusive: <b>kids should NEVER be held down for a medical procedure</b>. With VERY few exceptions, parents should also never be asked to leave the room. Your child needs you.</p>
        
        <p>As a Pain Champion, you may have to step up and ask for or even insist on correct comfort positioning!</p>
        <Accordions contentAccordions content={[{
          heading: <p>Speak Up</p>,
          body: <>
            <SpeechBox pointerVariant="top" speakup>
              <h4>We’re going to do some comfort positioning to keep him/her comfortable and calm. Does this position work for what you need to do?</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>I can climb up on the bed/table/chair with my child.</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>No, they are not too old for this. No one it too old to be comforted by someone they trust.</h4>
            </SpeechBox>
            <p>Common pitfall:  Some providers still believe the only way to do a procedure safely is to hold a child down. The research is VERY clear: children should NEVER be held down, especially on their backs.</p>
            <p>If someone tries to do this you may need to say:</p>
            <SpeechBox pointerVariant="top" speakup>
              <h4>You are not going to hold my child down like that. We can work together to find another way. The research is very clear on how that can traumatize kids.</h4>
            </SpeechBox>
            <p>You should also always insist on being there for procedures as long as you can remain calm. If kids have to do something hard, they shouldn’t have to do it alone.</p>
          </>
        }]} />

        <p>Don't worry if you or others might think your child is "too old" to sit on your lap. We are never too old to be comforted by the people we love.</p>

        <p>How we talk to kids during comfort positioning is really important for helping them feel good and safe. Click here for some powerful talk suggestions!</p>

        <Accordions contentAccordions content={[{
          heading: <p>Comfort Talk</p>,
          body: <>
            <SpeechBox pointerVariant="top" speakup>
              <h4>I’m right here with you.</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>Breathe along with me. Do you feel by big, slow, deep breaths?</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>Feel my calm...I’ve got you.</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>You are doing a great job.</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>I’m just going to help your arm/leg/etc remember to stay nice and still while we focus on what you want to focus on...</h4>
            </SpeechBox>
          </>
        }]} />
        
      </ContentBoxWithImage>

      <Quiz
        trigger="4"
        imageDisplay="contain"
        buttonDisplay="row"
        questions={[{
          src: Quiz1,
          alt: '',
          heading: "1 of 4",
          question: <p>Can you identify good comfort positioning?</p>,
          options: [{
            option: <p>Good</p>,
            correct: true,
          }, {
            option: <p>Poor</p>,
          }],
          feedback: {
            generic: <>
              <p><b>This is a 'Back-to-Chest Bear Hug.' It works well for arm procedures.</b> Have your child sit on your lap facing away from you. You can hug them from behind. Your child can turn his or her head toward or away from the procedure. Your child’s arm can go under or over your arm.</p>
            </>,
          },
        }, {
          src: Quiz2,
          alt: '',
          heading: "2 of 4",
          question: <p>Can you identify good comfort positioning?</p>,
          options: [{
            option: <p>Good</p>,
            correct: true,
          }, {
            option: <p>Poor</p>,
          }],
          feedback: {
            generic: <>
              <p><b>This is a 'Chest-to-Chest Bear Hug.' It works well for hand or arm procedures.</b> Have your child sit on your lap, facing you. Wrap the child’s legs around your waist. Your child can turn their head toward or away from the procedure. Your child’s arm can go under or over your arm.</p>
            </>,
          },
        }, {
          src: Quiz3,
          alt: '',
          heading: "3 of 4",
          question: <p>Can you identify good comfort positioning?</p>,
          options: [{
            option: <p>Good</p>,
          }, {
            option: <p>Poor</p>,
            correct: true,
          }],
          feedback: {
            generic: <>
              <p><b>This is incorrect comfort positioning.</b> The research is conclusive: kids should NEVER be held down for a medical procedure. Again, as a Pain Champion, you may have to step up and insist on correct comfort positioning.</p>
            </>,
          },
        }, {
          src: Quiz4,
          alt: '',
          heading: "4 of 4",
          question: <p>Can you identify good comfort positioning?</p>,
          options: [{
            option: <p>Good</p>,
            correct: true,
          }, {
            option: <p>Poor</p>,
          }],
          feedback: {
            generic: <>
              <p><b>This is a 'Sideways Lap Sit.' It works best for leg procedures.</b> Have your child sit on your lap facing sideways. Secure the child’s arm with your own arm. Secure his or her legs with your own leg.</p>
            </>,
          },
        }]}
      />
    </TimeSkipWrap>

    <TimeSkipWrap gate="4" hours now>
      <Belt heading="PLEASE SHOW ME MORE TOOLS">
        <BeltSlide
          heading="Distraction (AKA Alternative Focus)"
          imgSrc={ImgSrc6}
          imgAlt="Child reading a book during a shot"
          videoSrc={null}
          accordions={[{
            heading: <p>What is it?</p>,
            body: <p>You undoubtedly already know how good your child is at ignoring pretty much everything when they are really into a game or video. A bomb could drop in the room, and they probably wouldn't notice. While that can be annoying when you want them to do something, it is REALLY useful during or waiting for pokes and procedures. It turns out that when they are super focused on something, they are actually changing the way their body notices and processes pain signals. We can use that to help them feel better…and it works even better when they choose that focus!</p>
          }, {
            heading: <p>How do I prepare?</p>,
            body: <p>Talk with your child about what they would like to focus on: a video, a book, bubbles, a favorite toy, etc!. Remember, choice is power, and having power helps us feel in control.</p>
          }]}
        />
      </Belt>

      <Quiz
        trigger="5"
        nowTrigger="14"
        questions={[{
          src: Quiz5,
          alt: 'John is calmly waiting for a poke in his arm while playing a game. (Two states: with and without game)',
          heading: "1 of 3",
          question: <>
            <p>John likes to play video games and asked if you could bring a device for him to play on during his poke.</p>
            <p><b>Which do you give him first?</b></p>
          </>,
          options: [{
            option: <p>Book</p>,
          }, {
            option: <p>Nothing</p>,
          }, {
            option: <p>Device</p>,
            correct: true,
          }],
          feedback: {
            incorrect: <>
              <p>That's not quite right. Try again.</p>
            </>,
            correct: <>
              <p>That's right! Now is one of the rare times for you to encourage your child to play on a phone or tablet, if you have one, and if not ask if the medical office might have one to borrow. Encourage your child to play while waiting as well as during because it helps keep anxiety down, and gives them something positive to focus on.</p>
            </>,
            correctSrc: QuizFB5,
          },
        }, {
          src: Quiz6,
          alt: 'Sarah is happily playing with a toy. (Two states: with and without toy)',
          heading: "2 of 3",
          question: <>
            <p>Sarah is happily playing while waiting, but would like to watch as the poke happens. The nurse tells her not to look, but she says she wants to see it.</p>
            <p><b>Which do you give her first?</b></p>
          </>,
          options: [{
            option: <p>Book</p>,
          }, {
            option: <p>Nothing</p>,
            correct: true,
          }, {
            option: <p>Device</p>,
          }],
          feedback: {
            incorrect: <>
              <p>That's not quite right. Try again.</p>
            </>,
            correct: <>
              <p>Some children may feel more in control by watching the poke, and that's fine too! Giving the kids the choice of watching or not is another way to make them feel powerful. If a provider tries to tell your child not to watch, just gently let them know that it helps your child to look, and praise your child for knowing what is best for them.</p>
            </>,
            correctSrc: QuizFB6,
          },
        }, {
          src: Quiz7,
          alt: 'Sarah is happily playing with a toy. (Two states: with and without toy)',
          heading: "3 of 3",
          question: <>
            <p>Frank keeps saying he doesn't want to watch the poke and is visibly anxious. You don't have any devices to offer him and are scrambling to find something to distract him.</p>
            <p><b>Which do you give him first?</b></p>
          </>,
          options: [{
            option: <p>Book</p>,
            correct: true,
          }, {
            option: <p>Nothing</p>,
          }, {
            option: <p>Device</p>,
          }],
          feedback: {
            incorrect: <>
              <p>That's not quite right. Try again.</p>
            </>,
            correct: <>
              <p>Great choice! Books and toys from home (or the doctor’s office) are a simple way to distract your child while they wait for their needle or procedure. Singing songs or telling stories work too! It’s really about giving them something else to focus on.</p>
            </>,
            correctSrc: QuizFB7,
          },
        }]}
      />
    </TimeSkipWrap>

    <TimeSkipWrap gate="5" hours>
      <ContentBox topBuffer>
      <Accordions contentAccordions content={[{
          heading: <p>SPEAK UP!</p>,
          body: <>
            <SpeechBox pointerVariant="top" speakup>
              <h4>She/he wants to wants to pay attention to their toy/video/book while you do the procedure. It helps him/her stay more calm and comfortable.</h4>
            </SpeechBox>
            <p>Common pitfall:  Some providers don’t believe that kids should watch a poke or procedure, but really, it should be the choice of the child.</p>
            <p>For some kids, it helps them feel more in control. You can always say:</p>
            <SpeechBox pointerVariant="top" speakup>
              <h4>Actually, it helps my child to watch.</h4>
            </SpeechBox>
          </>
        }]} />
        <p>Want some phrases to make that distraction as powerful as possible?</p>
        <Accordions contentAccordions content={[{
          heading: <p>Comfort Talk</p>,
          body: <>
            <SpeechBox pointerVariant="top" speakup>
              <h4>You get to choose what you want to focus on, and not pay attention to anything else.</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>When you focus on something nice it helps make it all much better.</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>We can let the nurse/tech/person do their job over there, and we can just pay attention to the fun stuff over here. </h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>Notice my hand warm, squeezing yours reminding you that you can do it!</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>Let’s look at your favorite show/book/video because it helps you be even more comfortable and in control.</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>(ask questions about whatever they have chosen to distract themselves with)</h4>
            </SpeechBox>
          </>
        }]} />

        <h4 className="no-mb">Quick Tips!</h4>
        <ul>
          <li>Whatever they are interested in works! It’s all about capturing and keeping focus and attention, so let them tell you the best choice.</li>
          <li>If a child does not want to watch a procedure, use the book/ipad/whatever to block their view or place it so the child has to turn their head away.</li>
          <li>Distraction is also helpful before the poke (and after)! It helps children keep nervous feelings from becoming overwhelming while they have to wait, and can help them recover after if needed.</li>
          <li>Be creative…and prepared! You might need to switch distraction options in the moment to recapture their attention.</li>
        </ul>
      </ContentBox>

      <Belt heading="PLEASE SHOW ME MORE TOOLS">
        <BeltSlide
          heading="Breathing"
          imgSrc={ImgSrc7}
          imgAlt="Child has eyes closed taking a breath"
          videoSrc="https://www.youtube.com/embed/ozf5CxbIugU"
          videoFullWidth
          accordions={[{
            heading: <p>What is it?</p>,
            body: <p>Deep breaths that go all the way to the belly are a really great way to calm the body and "turn down the volume" of pain signals. Breathing out, then taking easy slower breaths in and out calms kids and reduces their body's reaction to pain. Model for them your own slow breathing, and encourage them to do as you do. Do your best to time the out-breath with the moment the needle goes in.</p>
          }, {
            heading: <p>How do I prepare?</p>,
            body: <p>Bring along any accessories you want to use with breathing. For example, pinwheels and bubbles are great for because it’s distracting and you can’t do either one without taking a good deep breath! Practice those good deep breaths at home, and encourage your child to notice how it makes their body more relaxed and comfortable.</p>
          }]}
        />
      </Belt>

      <ContentBoxWithImage
        src={ImgSrc8} 
        alt="SuperMeg blows on a pinwheel"
        heading="BLOW THE PAIN AWAY!"
      >
        <p><b>Give it a breath of fresh air by making it fun!</b></p>
        <p>Sometimes it can be challenging to get especially little ones to take deep breaths, but there are some ways to make it easier.</p>
        <p>You can get your kid to blow onto a pinwheel!</p>
      </ContentBoxWithImage>

      <ContentBoxWithImage
        src={ImgSrc9} 
        alt="SuperMeg blows bubbles"
      >
        <p><b>Or blow them away with some bubbles!</b></p>
        <p>Sometimes medical providers can mistakenly believe that it isn't sanitary to use bubbles, but there is no evidence that is true. It is safe and really effective.</p>
        <p>Want some good words to use with breathing?</p>

        <Accordions contentAccordions content={[{
          heading: <p>Comfort Talk</p>,
          body: <>
            <SpeechBox pointerVariant="top" speakup>
              <h4>We’ll do this 5 times together ‘cause the more you breathe these nice slow calm breaths, the more your body feels nice and calm too</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>As you breath out, notice how that helps your body feel better and more relaxed.</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>That’s right… breath in the comfort, and breath out the bother…</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>Your breath is getting nice and slow…nice work!</h4>
            </SpeechBox>
          </>
        }]} />

        <h4 className="no-mb">Quick Tips!</h4>
        <ul>
          <li>Good breathing technique should have three steps: breathe in, hold, and release. The pace is slow and controlled and will be different based on what is comfortable for that person.</li>
          <li>Practice beforehand to find that good, comfortable pace, and to demonstrate how the body relaxes with each breath!</li>
          <li>Do your best to time the out-breath with the moment the needle is inserted.</li>
        </ul>
      </ContentBoxWithImage>
      
      <Trigger trigger="6" />
    </TimeSkipWrap>
  </section>;
}