import React from "react";
import { QuoteOpenIcon, QuoteCloseIcon } from "../../atoms/Icons";

interface Props {
  children: React.ReactNode;
  boxVariant?: "left" | "right";
  pointerVariant?: "top" | "bottom";
  small?: boolean;
  speakup?: boolean;
}

const bubbleTopData = {
  desktop: {
    class: "desktop",
    large: {
      viewBox: "0 0 1140 600",
      pathData: [
        "M29.414,92.358L1099.6,72.219c18.17-.7,32.54,13.445,32,31.545l-13.71,465.52c-0.49,16.366-14.87,28.95-32.03,28.163L42.289,574.823c-14.417-.66-26.411-13.262-26.86-28.193L2.981,123.078C2.49,106.719,14.29,92.944,29.414,92.358Z",
        "M1136.6,86.764l-13.71,465.52c-0.49,16.366-14.87,28.95-32.03,28.164L47.29,557.823c-14.417-.66-26.412-13.262-26.861-28.193L7.982,106.078C7.49,89.719,19.291,75.944,34.413,75.359L605.967,64.6,570.569,3l71.384,60.922L1104.6,55.219C1122.77,54.516,1137.14,68.664,1136.6,86.764Z",
      ],
    },
    small: {
      viewBox: "0 0 1140 350",
      pathData: [
        "M1090.12,82.55c18.79-.977,35.2,12.153,36.44,29.472l-11.22,197.763c1.36,19.069-13.98,34.2-34.03,33.638L39.8,319.967c-13.629-.38-23.735-13.295-22.711-28.746L3.77,129.076c0.946-14.284,12.181-26.333,25.221-27.011Z",
        "M1125.34,299.784c1.36,19.069-13.98,34.2-34.03,33.638L49.8,309.967c-13.629-.38-23.735-13.295-22.711-28.746L13.77,119.076c0.946-14.284,12.181-26.333,25.221-27.011L600.33,81.741,556.48,5.793l88.445,75.128L1100.12,72.55c18.79-.977,35.2,12.153,36.44,29.472Z",
      ],
    },
  },
  tablet: {
    class: "tablet",
    large: {
      viewBox: "0 0 820 600",
      pathData: [
        "M34.414,92.358L774.6,72.219c18.17-.7,32.537,13.445,31.994,31.545L792.89,569.284c-0.492,16.366-14.87,28.95-32.03,28.163L47.289,574.823c-14.417-.66-26.411-13.262-26.86-28.193L7.981,123.078C7.49,106.719,19.29,92.944,34.414,92.358Z",
        "M811.6,86.764l-13.708,465.52c-0.491,16.366-14.87,28.95-32.029,28.164L52.29,557.823c-14.417-.66-26.412-13.262-26.861-28.193L12.982,106.078C12.49,89.719,24.291,75.944,39.413,75.359l405.4-11.03L409.569,3l70.725,60.36L779.6,55.219C797.774,54.516,812.141,68.664,811.6,86.764Z",
      ],
    },
    small: {
      viewBox: "0 0 820 350",
      pathData: [
        "M770.116,82.55c18.8-.977,35.205,12.153,36.443,29.472L795.335,309.784c1.363,19.069-13.972,34.2-34.023,33.638L39.8,319.967c-13.629-.38-23.735-13.295-22.711-28.746L3.77,129.076c0.946-14.284,12.181-26.333,25.221-27.011Z",
        "M805.335,299.784c1.363,19.069-13.972,34.2-34.023,33.638L49.8,309.967c-13.629-.38-23.735-13.295-22.711-28.746L13.77,119.076c0.946-14.284,12.181-26.333,25.221-27.011L440.424,81.494l-43.943-75.7,87.171,74.563L780.116,72.55c18.8-.977,35.205,12.153,36.443,29.472Z",
      ],
    },
  },
  mobile: {
    class: "mobile",
    large: {
      viewBox: "0 0 590 600",
      pathData: [
        "M29.414,92.358L549.6,72.219c18.17-.7,32.537,13.445,31.994,31.545L567.89,569.284c-0.492,16.366-14.87,28.95-32.03,28.163L42.289,574.823c-14.417-.66-26.411-13.262-26.86-28.193L2.981,123.078C2.49,106.719,14.29,92.944,29.414,92.358Z",
        "M586.6,86.764l-13.708,465.52c-0.491,16.366-14.87,28.95-32.029,28.164L47.29,557.823c-14.417-.66-26.412-13.262-26.861-28.193L7.982,106.078C7.49,89.719,19.291,75.944,34.413,75.359L329.581,63.931,294.569,3l69.812,59.581L554.6,55.219C572.774,54.516,587.141,68.664,586.6,86.764Z",
      ],
    },
    small: {
      viewBox: "0 0 590 350",
      pathData: [
        "M535.116,82.55c18.8-.977,35.205,12.153,36.443,29.472L560.335,309.784c1.363,19.069-13.972,34.2-34.022,33.638L44.8,319.967c-13.629-.38-23.735-13.295-22.711-28.746L8.77,129.076c0.946-14.284,12.181-26.333,25.221-27.011Z",
        "M570.335,299.784c1.363,19.069-13.972,34.2-34.023,33.638L54.8,309.967c-13.629-.38-23.734-13.295-22.711-28.746L18.77,119.076c0.946-14.284,12.181-26.333,25.221-27.011L325.2,81.114,281.481,5.793l86.126,73.67L545.116,72.55c18.8-.977,35.205,12.153,36.443,29.472Z",
      ],
    },
  },
};

const bubbleBotData = {
  desktop: {
    class: "desktop",
    large: {
      viewBox: "0 0 1140 600",
      pathData: [
        "M38.124,19.766L1106.64,34.22c13.63,0.38,23.73,13.295,22.71,28.747l-9.68,426.145c-0.95,14.284-12.18,26.333-25.22,27.011L53.321,541.638c-18.8.976-35.205-12.153-36.443-29.472L4.1,53.4C2.74,34.335,18.075,19.207,38.124,19.766Z",
        "M1136.35,47.967l-9.68,426.144c-0.95,14.285-12.18,26.334-25.22,27.011l-438.2,10.74L570.793,590.4l44.665-77.362L60.321,526.638c-18.8.976-35.205-12.153-36.443-29.472L11.1,38.4C9.739,19.335,25.075,4.207,45.125,4.766L1113.64,19.22C1127.27,19.6,1137.37,32.515,1136.35,47.967Z",
      ],
    },
    small: {
      viewBox: "0 0 1140 350",
      pathData: [
        "M38.124,29.766L1106.64,44.22c13.63,0.38,23.73,13.295,22.71,28.746l-9.68,166.144c-0.95,14.285-12.18,26.334-25.22,27.012L53.321,291.638c-18.8.976-35.205-12.153-36.443-29.472L4.1,63.4C2.74,44.335,18.075,29.207,38.124,29.766Z",
        "M1136.35,47.967l-9.68,176.144c-0.95,14.285-12.18,26.334-25.22,27.011l-438.2,10.74L570.793,340.4l44.665-77.362-555.137,13.6c-18.8.976-35.205-12.153-36.443-29.472L11.1,38.4C9.739,19.335,25.075,4.207,45.125,4.766L1113.64,19.22C1127.27,19.6,1137.37,32.515,1136.35,47.967Z",
      ],
    },
  },
  tablet: {
    class: "tablet",
    large: {
      viewBox: "0 0 820 600",
      pathData: [
        "M34.414,92.358L774.6,72.219c18.17-.7,32.537,13.445,31.994,31.545L792.89,569.284c-0.492,16.366-14.87,28.95-32.03,28.163L47.289,574.823c-14.417-.66-26.411-13.262-26.86-28.193L7.981,123.078C7.49,106.719,19.29,92.944,34.414,92.358Z",
        "M811.6,86.764l-13.708,465.52c-0.491,16.366-14.87,28.95-32.029,28.164L52.29,557.823c-14.417-.66-26.412-13.262-26.861-28.193L12.982,106.078C12.49,89.719,24.291,75.944,39.413,75.359l405.4-11.03L409.569,3l70.725,60.36L779.6,55.219C797.774,54.516,812.141,68.664,811.6,86.764Z",
      ],
    },
    small: {
      viewBox: "0 0 820 350",
      pathData: [
        "M38.124,29.766L786.636,44.22c13.629,0.38,23.735,13.295,22.711,28.746l-9.679,166.144c-0.946,14.285-12.182,26.334-25.222,27.012L53.321,291.638c-18.8.976-35.205-12.153-36.443-29.472L4.1,63.4C2.739,44.335,18.075,29.207,38.124,29.766Z",
        "M816.347,47.967l-9.679,176.144c-0.946,14.285-12.182,26.334-25.222,27.011l-278.2,10.74L410.793,340.4l44.665-77.362-395.137,13.6c-18.8.976-35.205-12.153-36.443-29.472L11.1,38.4C9.739,19.335,25.075,4.207,45.125,4.766L793.636,19.22C807.265,19.6,817.371,32.515,816.347,47.967Z",
      ],
    },
  },
  mobile: {
    class: "mobile",
    large: {
      viewBox: "0 0 590 600",
      pathData: [
        "M29.414,92.358L549.6,72.219c18.17-.7,32.537,13.445,31.994,31.545L567.89,569.284c-0.492,16.366-14.87,28.95-32.03,28.163L42.289,574.823c-14.417-.66-26.411-13.262-26.86-28.193L2.981,123.078C2.49,106.719,14.29,92.944,29.414,92.358Z",
        "M586.6,86.764l-13.708,465.52c-0.491,16.366-14.87,28.95-32.029,28.164L47.29,557.823c-14.417-.66-26.412-13.262-26.861-28.193L7.982,106.078C7.49,89.719,19.291,75.944,34.413,75.359L329.581,63.931,294.569,3l69.812,59.581L554.6,55.219C572.774,54.516,587.141,68.664,586.6,86.764Z",
      ],
    },
    small: {
      viewBox: "0 0 590 350",
      pathData: [
        "M43.124,29.766L551.636,44.22c13.629,0.38,23.735,13.295,22.711,28.746l-9.679,166.144c-0.946,14.285-12.182,26.334-25.222,27.012L58.321,291.638c-18.8.976-35.205-12.153-36.443-29.472L9.1,63.4C7.74,44.335,23.075,29.207,43.124,29.766Z",
        "M581.347,47.967l-9.679,176.144c-0.946,14.285-12.182,26.334-25.222,27.011l-158.2,10.74L295.793,340.4l44.665-77.362-275.137,13.6c-18.8.976-35.205-12.153-36.443-29.472L16.1,38.4C14.739,19.335,30.075,4.207,50.125,4.766L558.636,19.22C572.265,19.6,582.371,32.515,581.347,47.967Z",
      ],
    },
  },
};

export const SpeechBox = ({
  children,
  boxVariant = "right",
  pointerVariant = "bottom",
  small,
  speakup
}: Props) => {
  const bubbleData =
    pointerVariant === "bottom" ? bubbleBotData : bubbleTopData;
  const screens = [bubbleData.desktop, bubbleData.tablet, bubbleData.mobile];
  return (
    <>
      {screens.map((screen) => {
        if (small)
          return (
            <div className={`${screen.class} speech-box ${speakup ? 'speakup-variant' : ''}`}>
              <div className={`speech-box-content small ${pointerVariant}`}>
                <div>
                  <QuoteOpenIcon />
                  {children}
                  <QuoteCloseIcon />
                </div>
              </div>
              <svg
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox={screen.small.viewBox}
                className={`speech-bubble ${screen.class} small ${pointerVariant}`}
              >
                <path
                  id="Shadow"
                  className="cls-1"
                  d={screen.small.pathData[0]}
                />
                <path
                  id="Content_Container"
                  data-name="Content Container"
                  className="cls-2"
                  d={screen.small.pathData[1]}
                />
              </svg>
            </div>
          );

        return (
          <div className={`speech-box ${screen.class} ${speakup ? 'speakup-variant' : ''}`}>
            <svg
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox={screen.large.viewBox}
              className={`speech-bubble ${screen.class} large ${pointerVariant}`}
            >
              <path
                id="Shadow"
                className="cls-1"
                d={screen.large.pathData[0]}
              />
              <path
                id="Content_Container"
                data-name="Content Container"
                className="cls-2"
                d={screen.large.pathData[1]}
              />
            </svg>
            <div className={`speech-box-content ${pointerVariant}`}>
              <div>
                <QuoteOpenIcon />
                {children}
                <QuoteCloseIcon />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
