import React from 'react'

interface Props {
  src: string;
  alt: string;
  classNames?: string;
};

export const Image = ({ src, alt, classNames }: Props) => {
  return <div className="image-wrap"><img src={src} alt={alt} className={`image ${classNames ? classNames : ''}`} /></div>;
}