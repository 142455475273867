import React from 'react';
import ImgSrc1 from '../../../assets/images/illustrations/2-HeroPosew_LightBeams.png';
import ImgSrc2 from '../../../assets/images/illustrations/3-SuperMegAndFoundationTeam.png';
import ImgSrc3 from '../../../assets/images/illustrations/4-PointingToViewer.png';
import ImgSrc4 from '../../../assets/images/illustrations/5-ReporterNewsFlash.png';
import ImgSrc5 from '../../../assets/images/illustrations/6-SuperMegTalkingWithTimepiece.png';
import ImgSrc6 from '../../../assets/images/illustrations/7-MedicalProfessionals.png';
import ImgSrc7 from '../../../assets/images/illustrations/8-SuperMegTalkingToMedicalProfessionals.png';
import ImgSrc8 from '../../../assets/images/illustrations/39-SuperMegLookingBossy.png';
import ImgSrc9 from '../../../assets/images/illustrations/10-SuperMegMeditating.png';
import ImgSrc10 from '../../../assets/images/illustrations/11-SuperMegShootingHypno-BeamsFromHerHead.png';
import ImgSrc11 from '../../../assets/images/illustrations/12-SuperMegBecomes6YearsOld.png';

import QuizQuestionImg from '../../../assets/images/illustrations/13-SuperMeg-6YearOldIsTalking.png';
import QuizCorrectFbImg from '../../../assets/images/illustrations/14-SuperMeg-6YearOldIsHappy.png';
import QuizIncorrectFbImg from '../../../assets/images/illustrations/15-SuperMeg-6YearOldIsUpset.png';
import { ContentBoxWithImage, SpeechBoxWithImage, SpeechBox, VideoBox, TimeBranch } from '../../molecules';
import { Accordions, Quiz, TimeSkipWrap } from '../../organisms';

export const Section1: React.FC = () => {
  return <section id="section-1">
    <TimeSkipWrap gate="0.5" hours now>
      <ContentBoxWithImage
        src={ImgSrc1} 
        alt="SuperMeg in hero pose with light beaming from behind her"
        heading="Welcome!"
      >
        <p>It is I -- the immunization sensation, the injectionist perfectionist, the original hot shot -- SuperMeg! I’m here to teach you all you need to know about helping you and your kid create comfort and prevent pain!</p>
        <p>Before I teach you the special abilities that will make you an honorary <b>Pain Champion</b>, you probably want to see some credentials, right?</p>
      </ContentBoxWithImage>

      <ContentBoxWithImage
        src={ImgSrc2} 
        alt="SuperMeg training with the Meg Foundation"
      >
        <p>The real secret of my incredible success is that I’m friends with this group of super-smart experts at <b>The Meg Foundation</b>.</p>
        <p>They're all about <b>less pain</b> and <b>more comfort</b> for kids, which just so happens to be my mission too! Only they've been at it for a lot longer than I have.</p>
        <p>They've spent years gathering proven strategies backed by <b>science, research</b>, and <b>world-class experts</b>.</p>
        <p>They taught me all of these super abilities with only one condition -- that I help as many families as possible with this knowledge...</p>
      </ContentBoxWithImage>

      <SpeechBoxWithImage
        src={ImgSrc3} 
        alt="SuperMeg pointing to the learner"
      >
        <h4>I take this mission seriously.</h4>
        <h4>I'm here to help you and your family feel more comfortable and powerful when you go to the doctor.</h4>
        <h4>I'll give you all the tools you need to help your child be more at ease with needle pokes and other uncomfortable procedures.</h4>
      </SpeechBoxWithImage>

      <SpeechBoxWithImage
        src={ImgSrc4} 
        alt="SuperMeg pointing to the learner"
        heading="THIS JUST IN..."
      >
        <h4>Breaking news!</h4>
        <h4>Local Pain Champion SuperMeg is delivering a historic speech on the steps of city hall. We're now crossing live to the scene!</h4>
      </SpeechBoxWithImage>

      <VideoBox heading="Video" src="https://www.youtube.com/embed/RIqIbBZ9_qE">
        <p>Watch the Wield the Shield video.</p>
      </VideoBox>

      <SpeechBoxWithImage
        src={ImgSrc5} 
        alt="SuperMeg in hero pose with light beaming from behind her"
        heading="BECOME A PAIN CHAMPION!"
        small
      >
        <h4>I want to give you the information that works best for you and your situation! How long you do you have until your appointment?</h4>
      </SpeechBoxWithImage>

      <TimeBranch />
    </TimeSkipWrap>

    <TimeSkipWrap gate="0.75">
      <ContentBoxWithImage
        src={ImgSrc6} 
        alt="Smiling medical professionals"
      >
        <p>Booyah! We've got some time!</p>
        <p>Since the appointment is still a few days away, we can work with your doctor to make this experience as good as possible!.</p>
        <p>For many reasons, and despite their best intentions, some medical providers don't know the best pain management practices for kids.</p>
      </ContentBoxWithImage>
      
      <ContentBoxWithImage
        src={ImgSrc7} 
        alt="SuperMeg talking to medical professionals"
      >
        <p>As a Pain Champion, it is likely you will need to <b>step up</b> and <b>ask</b> for what you want and need to make sure your child and is as comfortable and calm as possible.</p>
        <p>It can feel a little weird at first, but it's all in spirit of doing what is best for your child. Any good medical provider can get behind that! They appreciate you chiming in on what helps, because everyone wants things to go as smoothly as possible.</p>
      </ContentBoxWithImage>
    </TimeSkipWrap>

    <TimeSkipWrap gate="1" hours now>
      <ContentBoxWithImage
        src={ImgSrc8} 
        alt="SuperMeg sitting at a computer and holding a 'batphone' style telephone"
        loadScript="//embed.typeform.com/next/embed.js"
      >
        <p>If it seems like it could be uncomfortable to bring it up with your doc, no sweat, I've got your back! I’m going to teach you great ways to collaborate with your medical team.</p>
        <p>First thing to do is reach out to your doc and find out what they do to manage needle pain, and what resources they have available.</p>
        <p>Need help knowing what to say?</p>
        
        <Accordions contentAccordions content={[{
          heading: <p>SPEAK UP!</p>,
          body: <>
            <SpeechBox pointerVariant="top" speakup>
              <h4>Hi! I’m bringing my child in for a (flu shot/blood test/fill in procedure here) in a few days. Can you tell me about what your team will do to manage her pain?</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>Is your team open to prescribing and/or using topical anesthetics? Do you have them or should I bring my own? Where should I put the cream on to make sure it is in the right place/places?</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>Do you use vibration, like Buzzy Bee or something like it? </h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>Do you use cold spray?</h4>
            </SpeechBox>
            <p>Common pitfall: Many medical providers haven’t been properly trained in pain management, so you might have to insist.</p>
            <p>If there is a lot of push back, you can say:</p>
            <SpeechBox pointerVariant="top" speakup>
              <h4>I appreciate where you are coming from, but I know we’d all like my child to have a good experience.</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>Everything I’ve asked about is recommended by best practice guidelines and the major pediatric medical organizations.</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>Can I get your email address to I can send you some information about the research?</h4>
            </SpeechBox>
            <SpeechBox pointerVariant="top" speakup>
              <h4>I know these things will help it go better for my child and for your providers.</h4>
            </SpeechBox>
          </>
        }]} />

        <p>We can help ensure that your medical providers have all the current research and the best practice guidelines by sending them all the information directly.</p>

        <p>Our resources are supported by nearly every major pain and pediatric organization in the world!</p>

        <p>Simply provide their email address and we'll take care of the rest.</p>

        <button data-tf-popup="kzYCMpBo" data-tf-hide-headers data-tf-hide-footer className="tfButton">Send Resources</button>
      </ContentBoxWithImage>
    </TimeSkipWrap>
    <TimeSkipWrap gate="1" hours>
      <ContentBoxWithImage
        src={ImgSrc9} 
        alt="SuperMeg meditates"
        heading="LET'S CONTINUE"
      >
        <p>Now that's settled, let's take a second to chill and talk about your first superpower. Surprise! You already have a superpower!</p>
        <p>Your level of stress (or calm) is one of the biggest predictors of how your child copes during a needle poke. Yep, stress is contagious, but thankfully, so is <b>calm!</b></p>
        <p>You can harness that superpower by taking a deep breath, letting those shoulders relax, and finding that happy place...whatever works for you!</p>
        <p>Remind yourself how awesome you are for stepping up as a Pain Champion for your kid! There's nobody as qualified to have their back. After all, you know them better than anyone else.</p>
        <p>Doctors are experts in medicine, but YOU are the expert when it comes to your child. We need BOTH to do health care well!</p>
      </ContentBoxWithImage>

      <ContentBoxWithImage
        src={ImgSrc10} 
        alt="SuperMeg shoots hypno-beams out of her brain"
      >
        <p>Part of passing on that calm is paying attention to our language….literally what we say to our kids.</p>
        <p>The <b>words</b> we use are your second <b>superpower</b>. Like a hypnotic ray, they have the power to shape and transform EVERYTHING, including how we feel and what we focus on. It's really amazing!</p>
        <p>When the language we use is scary or negative, then kids expect to have a bad experience.</p>
        <p>BUT we can also use that power for good! When we use language that frames things as positive and heroic, then kids go in with a powerful mindset and expectations of success!</p>
      </ContentBoxWithImage>

      <ContentBoxWithImage
        src={ImgSrc11} 
        alt="SuperMeg becomes 6 years old"
      >
        <p><b>Let's do a quick role-play!</b></p>
        <p>I'll be your child, and you play my parent.</p>
        <p>We are talking about the fact I need to go the doctor and get a poke.</p>
        <p><b>Let's give it a go!</b></p>
      </ContentBoxWithImage>

      <Quiz
        trigger="2"
        questions={[{
          scenario: <h4>Am I getting a needle when I go to the doctor?</h4>,
          src: QuizQuestionImg,
          alt: 'SuperMeg (6 years old) talking pose',
          heading: "1 of 3",
          question: <p>How would you respond?</p>,
          options: [{
            option: <p>No, sweetie. That won't happen at all.</p>
          }, {
            option: <p>I’m not totally sure but I think so. The good news is that there are a lot of ways we can make a poke no big deal, and you can feel comfortable and in control. Let’s work on our plan together!</p>,
            correct: true,
          }],
          feedback: {
            generic: <>
              <p>Not telling me about a poke might make me feel better in the moment, but I'll be in for a pretty big shock when we go to the doctors, and I’m more likely to get upset and have a hard time coping. It also might make it hard to trust you, and that feels scary.</p>
              <p>It's much better to be honest from the get-go so we can plan ahead together. EVERYONE feels better about doing hard things when we have a plan on how to tackle it! It also means we can decide HOW to cope, and practice the skills.</p>
            </>,
            incorrectSrc: QuizIncorrectFbImg,
            correctSrc: QuizCorrectFbImg,
            alt: 'SuperMeg (6 years old) looks confident',
          },
        }, {
          scenario: <h4>Will it hurt?</h4>,
          src: QuizQuestionImg,
          alt: 'SuperMeg (6 years old) talking pose',
          heading: "2 of 3",
          question: <p>How would you respond?</p>,
          options: [{
            option: <p>No, it’s not going to hurt. Just try not to cry.</p>
          }, {
            option: <p>You may feel pressure, squeezing or you might not notice anything at all. It might bother you a little bit, or it might not bother you at all. There are a lot of ways we can make it more comfortable.</p>,
            correct: true,
          }],
          feedback: {
            generic: <>
              <p>Open and honest communication is good, and lying to kids just teaches them not to trust you, which makes them more scared and nervous. We can't always promise a pain free experience, but we can promise ways to make it more comfortable. Also, telling kids not to cry tells kids both 1) their feelings aren’t going to be accepted and somehow “bad” and 2) it’s very likely there is something to cry about.</p>
              <p>Using words such as 'poke' and 'bother' as opposed to 'sting' or 'hurt' lessen anxiety and feel a lot less scary. It helps kids think about experiencing a poke differently and more positively, and opens up possibilities for using skills to cope and make it “no big deal.”</p>
            </>,
            incorrectSrc: QuizIncorrectFbImg,
            correctSrc: QuizCorrectFbImg,
            alt: 'SuperMeg (6 years old) looks confident',
          },
        }, {
          scenario: <h4>I don’t want to get a poke! Why do I have to?</h4>,
          src: QuizQuestionImg,
          alt: 'SuperMeg (6 years old) talking pose',
          heading: "3 of 3",
          question: <p>How would you respond?</p>,
          options: [{
            option: <p>I'm sorry, kiddo. I don’t know. The doctor says you have to.</p>
          }, {
            option: <p>(For blood draws) Getting a sample of your blood helps the doctor take good care of you so we can get (or keep) you healthy. (For pokes) It’s the way we give you the medicine you need to keep you healthy and strong!</p>,
            correct: true,
          }],
          feedback: {
            generic: <>
              <p>We apologize when we do something wrong, or something bad has happened, so it's not a good idea to apologize for doing something that is good for your child's health. They pick up on that. Also, a child should always know WHY a poke or procedure is happening. When we have to do something hard, we need a good reason to do it. “Blaming” the doctor is not fair, and it sets up bad feelings towards the person trying to help.</p>
              <p>If you speak positively about a poke as manageable and explain how it helps, your child is more likely to feel positive about it as well!</p>
            </>,
            incorrectSrc: QuizIncorrectFbImg,
            correctSrc: QuizCorrectFbImg,
            alt: 'SuperMeg (6 years old) looks confident',
          },
        }]}
        results={{
          src: QuizCorrectFbImg,
          alt: 'SuperMeg shoots hypno-beams out of her brain.',
          content: <>
            <p><b>Great job!</b></p>
            <p>Remember, focus on <b>remaining calm</b> even if you're nervous, because those little people are really good at picking up on our energy and language.</p>
            <p>Speak in a level, <b>soft tone</b> of voice, and notice how things change for you and everyone else when you deliberately slow down your pace.</p>
            <p><b>Be honest</b> while using gentle words that don't cause unnecessary anxiety.</p>
            <p>Want to know more examples of what I mean?</p>
            <Accordions contentAccordions content={[{
              heading: <p>Comfort Talk</p>,
              body: <>
                <SpeechBox pointerVariant="top" speakup>
                  <h4>I know in the past you’ve been nervous about a poke, but the good news is that we can use some new strategies that will help you be comfortable and in control.</h4>
                </SpeechBox>
                <SpeechBox pointerVariant="top" speakup>
                  <h4>It’s okay to feel nervous/worried/etc about getting the poke...we are going to find ways together to make it easier and better.</h4>
                </SpeechBox>
                <SpeechBox pointerVariant="top" speakup>
                  <h4>There are many ways we can help it not hurt.</h4>
                </SpeechBox>
                <SpeechBox pointerVariant="top" speakup>
                  <h4>You can choose what sounds good to you!</h4>
                </SpeechBox>
                <SpeechBox pointerVariant="top" speakup>
                  <h4>We can write down our plan to we know what to expect.</h4>
                </SpeechBox>
                <SpeechBox pointerVariant="top" speakup>
                  <h4>We can practice what you want to do to help keep it from bothering you so we both feel better and more prepared!</h4>
                </SpeechBox>
              </>
            }]} />
          </>
        }}
      />
    </TimeSkipWrap>
  </section>;
}