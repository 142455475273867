import React from 'react';

interface Props {
  heading?: string;
  children: React.ReactNode;
  topBuffer?: boolean;
};

export const ContentBox = ({ heading, children, topBuffer = false }: Props) => {
  return <div className={`${topBuffer ? 'top-buffer' : ''}`}>
    {heading && <h2 className="text-center above-box">{heading}</h2>}
    <div className="content box">
      {children}
    </div>
  </div>;
}