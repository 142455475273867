import React, { useContext } from 'react';
import { TimeContext } from '../../../App';
import calendarIcon from '../../../assets/images/Icon-Calendar.png';
import alarmIcon from '../../../assets/images/Icon-Alarm.png';
import clockIcon from '../../../assets/images/Icon-Clock.png';
import rightArrow from '../../../assets/images/Arrows-Right.png';
import { scrollDown } from '../../../utils/scrollDown';

export const TimeBranch = () => {
  const { setHasTime, setOnlyHours, setOnlyNow, setGates, gates } = useContext(TimeContext);
  const handleClick = (index: number) => {
    if (index === 1) {
      setHasTime && setHasTime(true);
      setOnlyHours && setOnlyHours(false);
      setOnlyNow && setOnlyNow(false);
      setGates && setGates(["0", "0.5", "0.75", "1"]);
    } else if (index === 2) {
      setHasTime && setHasTime(false);
      setOnlyHours && setOnlyHours(true);
      setOnlyNow && setOnlyNow(false);
      setGates && setGates(["0", "0.5", "0.75", "1"]);
    } else if (index === 3) {
      setHasTime && setHasTime(false);
      setOnlyHours && setOnlyHours(false);
      setOnlyNow && setOnlyNow(true);
      setGates && setGates(["0", "0.5", "0.75", "1", "2"]);
    }
    scrollDown();
  };

  return <div className="content">
    <div className="time-branch">
      <div onClick={() => handleClick(1)}>
        <img src={calendarIcon} alt="" />
        <h4>Days</h4>
        <img src={rightArrow} alt="" />
      </div>
      <div onClick={() => handleClick(2)}>
        <img src={clockIcon} alt="" />
        <h4>Hours</h4>
        <img src={rightArrow} alt="" />
      </div>
      <div onClick={() => handleClick(3)}>
        <img src={alarmIcon} alt="" />
        <h4>Help Me Now</h4>
        <img src={rightArrow} alt="" />
      </div>
    </div>
  </div>;
}