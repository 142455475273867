import React, { useState, useEffect } from 'react';

export const Menu: React.FC = () => {
  const [scroll, setScroll] = useState(0);
  const [open, setOpen] = useState(false);

  const handleScroll = () => {
    const el = document.querySelector('html');
    if (el) {
      setScroll((el.scrollTop / el.scrollHeight) * 100 + 2)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  });

  const handlePress = () => {
    setOpen(!open);
  }

  const scrollPage = (amount: number) => {
    window.scroll({
      top: amount,
      behavior: 'smooth'
    });
    setOpen(false);
  }

  return <>
    <div id="menu">
      <div className="content">
        <div className={`hamburger ${open ? 'open' : ''}`} onClick={handlePress}>
          <span />
          <span />
          <span />
        </div>
        <h4>Super Meg <span>&amp; the Pain Champions</span></h4>
      </div>
      <div id="progress">
        <div id="progress-complete" style={{
          flex: `${scroll}% 1`
        }} />
        <div id="progress-bg" style={{
          flex: `${100 - scroll}% 1`
        }} />
      </div>
    </div>
    <div id="menu-content" className={`${open ? 'open' : ''}`}>
      <div>
        <div>
          <div onClick={() => window.location.reload()}>
            <h4>Start Over</h4>
          </div>
        </div>
      </div>
    </div>
  </>;
}