import React from 'react';
import { DownArrow } from '../../atoms';
import { Hero } from '../../atoms';
import { Trigger } from '../../molecules';
import { TimeSkipWrap } from '../../organisms';

export const Begin: React.FC = () => {
  return <section id="begin">
    <TimeSkipWrap gate="0" hours now>
      <Hero />

      <div className="bar">
        <div className="content">
          <Trigger trigger="0.5" text="Let's Begin" />
          <DownArrow />
        </div>
      </div>
    </TimeSkipWrap>
  </section>;
}