import React from 'react';
import { Video } from '../Video';

interface Props {
  src: string;
  heading?: string;
  children: React.ReactNode;
};

export const VideoBox = ({ src, heading, children }: Props) => {
  return <div className={`video-box ${!heading ? 'no-heading' : ''}`}>
    {heading && <h2 className="text-center above-image">{heading}</h2>}
    <Video src={src} />
    <div className="content box">
      {children}
    </div>
  </div>;
}