import React from 'react';
import { Image } from '../../atoms/Images/Image';

interface Props {
  src: string;
  alt: string;
  heading?: string;
  children: React.ReactNode;
  topHeading?: boolean;
  loadScript?: string;
};

export const ContentBoxWithImage = ({ src, alt, heading, children, topHeading, loadScript }: Props) => {
  React.useEffect(() => {
    if (loadScript) {
      const script = document.createElement("script");

      script.src = loadScript;
      script.async = true;

      document.body.appendChild(script);
    }
  }, [loadScript]);

  return <div className="content-box-with-image">
    {heading && topHeading && <h2 className="text-center above-image">{heading}</h2>}
    <Image src={src} alt={alt} />
    {heading && !topHeading && <h2 className="text-center above-box">{heading}</h2>}
    <div className={`content box ${!heading ? 'no-heading' : ''} ${topHeading ? 'top-heading' : ''}`}>
      {children}
    </div>
  </div>;
}