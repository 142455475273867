import React, { useState, useEffect } from 'react';
import { LeftArrow, RightArrow } from '../../atoms/Icons';
interface Props {
  heading: string;
  children: any;
};

export const Belt = ({ heading, children }: Props) => {
  const [active, setActive] = useState(0);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);

  useEffect(() => {
    if (active === 0) {
      setPrevDisabled(true);
      setNextDisabled(false);
    } else if (active === children.length - 1) {
      setPrevDisabled(false);
      setNextDisabled(true);
    }
  }, [active, children.length]);

  const handlePrev = () => {
    if (!prevDisabled) setActive(active - 1);
  };

  const handleNext = () => {
    if (!nextDisabled) setActive(active + 1);
  };

  const handleCrumb = (i: number) => {
    setActive(i);
  };

  const renderChildren = () => {
    if (!children.length) return children;
    return children.map((child: React.ReactNode, i: number) => {
      if (active === i) return child;
      return null;
    })
  };

  const renderCrumbs = () => {
    if (!children.length) return null;
    return children.map((_child: React.ReactNode, i: number) => {
      if (active === i) return <div onClick={() => handleCrumb(i)} className="crumb active" />;
      return <div onClick={() => handleCrumb(i)} className="crumb" />;
    })
  };

  return <>
    <div className="belt-wrap">
      <div className="belt">
        <div className={`belt-heading ${!children.length ? 'single' : ''}`}>
          {children.length > 1 && <div onClick={handlePrev} className={`${prevDisabled ? 'disabled' : ''}`}><LeftArrow /></div>}
          <h2>{heading}</h2>
          {children.length > 1 && <div onClick={handleNext} className={`${nextDisabled ? 'disabled' : ''}`}><RightArrow /></div>}
        </div>
        {renderChildren()}
        {children.length > 1 && <div className="belt-crumbs">{renderCrumbs()}</div>}
      </div>
    </div>
  </>;
}