import React from 'react';
import { Image } from '../../Images/Image';
import BG from '../../../../assets/images/illustrations/1-ComicCover-BG.png';
import FG from '../../../../assets/images/illustrations/1-ComicCover-Comic.png';

export const Hero = () => {
  return <div aria-label="Comic book cover" className="hero" style={{
    backgroundImage: `url(${BG})`
  }} >
    <Image src={FG} alt="" />
  </div>;
}