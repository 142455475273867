import React from 'react';
import { Video } from '../../molecules/Video';
import { Accordions } from '../Accordions';

interface BeltAccordion {
  heading: React.ReactNode;
  body: React.ReactNode;
}

interface Props {
  heading: string;
  imgSrc: string;
  imgAlt: string;
  videoSrc: string | null;
  accordions: BeltAccordion[];
  videoFullWidth?: boolean;
};

export const BeltSlide = ({ heading, imgSrc, imgAlt, videoSrc, accordions, videoFullWidth = false }: Props) => {
  return <>
    <div className="belt-slide">
      <div className={`belt-slide-content ${videoSrc ? '' : 'no-video'}`}>
        <div className="belt-slide-content-image">
          <h3>{heading}</h3>
          <img src={imgSrc} alt={imgAlt} />
        </div>
        <Accordions content={accordions}/>
      </div>
      {videoSrc && (<div className={`belt-slide-video ${videoFullWidth ? 'full-width' : ''}`}>
        <Video src={videoSrc} />
        <p>Watch the video</p>
      </div>)}
    </div>
  </>;
}