import React from 'react';
import { Accordion } from '../Accordion';

interface Accordion {
  heading: React.ReactNode;
  body: React.ReactNode;
}

interface Props {
  content: Accordion[];
  contentAccordions?: boolean;
};

export const Accordions = ({ content, contentAccordions = false }: Props) => {
  return <>
    <div className={`accordions ${contentAccordions ? 'content-accordions' : ''}`}>
      {content.map((accordion: Accordion, i: number) => {
        const { heading, body } = accordion;
        return <Accordion contentAccordions={contentAccordions} heading={heading} body={body} key={i} />
      })}
    </div>
  </>;
}