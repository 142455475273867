import React, { useState } from 'react';
import { UpArrow, DownArrow, SpeechIcon } from '../../atoms';

interface Props {
  heading: React.ReactNode;
  body: React.ReactNode;
  initOpen?: boolean;
  contentAccordions?: boolean;
};

export const Accordion = ({ heading, body, initOpen = false, contentAccordions = false }: Props) => {
  const [open, setOpen] = useState(initOpen);

  const handleClick = () => {
    setOpen(!open);
  };

  return <>
    <div className="accordion">
      <div className={`accordion-heading ${open ? 'open' : ''}`} onClick={handleClick}>
        {contentAccordions && <SpeechIcon />}
        {heading}
        {open ? <UpArrow /> : <DownArrow />}
      </div>
      {open && (
        <div className="accordion-body">
          {body}
        </div>
      )}
    </div>
  </>;
}